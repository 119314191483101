const sellerRoutes = [
  {
    label: 'Dashboard',
    icon: 'chart-pie',
    to: '/',
  },
  {
    label: 'Marketplace',
    disabled: true,
  },
  {
    label: 'Orders',
    to: '/orders',
    icon: 'shopping-cart',
  },
  {
    label: 'Products',
    to: '/products',
    icon: 'tag',
  },
  {
    label: 'Reviews',
    to: '/reviews',
    icon: 'star',
  },
  {
    label: 'Coupons',
    to: '/coupons',
    icon: 'percentage',
  },
  {
    label: 'Settings',
    disabled: true,
  },
  {
    label: 'Account',
    to: '/account',
    icon: 'user-circle',
  },
  {
    label: 'Store details',
    to: '/store',
    icon: 'store',
  },
  {
    label: 'Warehouse',
    to: '/warehouse',
    icon: 'warehouse',
  },
  {
    label: 'Shipping',
    to: '/shipping',
    icon: 'truck',
  },
  {
    label: 'Packages',
    to: '/packages',
    icon: 'box',
  },
];

export default sellerRoutes;
