import { DEFAULT_PAGE, DEFAULT_PAGE_LIMIT } from 'constants/constants';
import { useURLParams } from 'hooks/useURLParams';
import { useEffect } from 'react';

export const usePaginationParams = () => {
  const { params, handleParamsChange } = useURLParams() as any;
  const limit = parseInt(params?.limit) || DEFAULT_PAGE_LIMIT;
  const page = parseInt(params?.page) || DEFAULT_PAGE;
  useEffect(() => {
    handleParamsChange({ page, limit });
  }, []);
  const onPaginationChange = (p: number = page, l: number = limit) => {
    handleParamsChange({ page: p, limit: l });
  };
  return {
    page,
    limit,
    onPaginationChange,
  };
};
