import { PaymentStatuses } from 'constants/orders';
import IconFA from './IconFA';
import { Badge } from '@mantine/core';
import { getColor } from 'helpers/utils';
import { t } from 'i18next';

const PaymentStatus = ({ status = 'unpaid', fullWidth = false }) => {
  return (
    <Badge
      fullWidth={fullWidth}
      variant="light"
      color={getColor(PaymentStatuses?.[status]?.bg)}
      className="w-max"
      // rightSection={<IconFA color={getColor(PaymentStatuses?.[status]?.bg)} />}
    >
      {t(PaymentStatuses?.[status]?.label)}
    </Badge>
  );
};

export default PaymentStatus;
