import {
  CONFIRM_PASSWORD_ERROR_MESSAGE,
  EMAIL_INVALID_ERROR_MESSAGE,
  PASSWORD_PATTERN,
  PASSWORD_PATTERN_ERROR_MESSAGE,
  PASSWORD_REQUIRE_ERROR_MESSAGE,
  PHONE_NO_PATTERN,
} from 'constants/constants';
import { t } from 'i18next';
import * as yup from 'yup';
import validator from 'validator';

export const systemUserSchema = (isEdit: boolean) =>
  yup.object({
    first_name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('register.form.first_name'),
      }),
    ),
    last_name: yup.string().required(
      t('form.invalid.required', {
        fieldName: t('register.form.last_name'),
      }),
    ),
    status: yup.string().required(),
    permission_id: yup.string().required(),
    email: yup
      .string()
      .email(EMAIL_INVALID_ERROR_MESSAGE)
      .required(EMAIL_INVALID_ERROR_MESSAGE),
    phone_number: yup
      .string()
      .test({
        name: 'is-phone-number',
        test(value: any = '') {
          return (
            !value ||
            value?.length < 4 ||
            validator.isMobilePhone(value, undefined, {
              strictMode: false,
            })
          );
        },
        message: t('form.invalid.invalid', {
          fieldName: t('register.form.phone'),
        }),
      })
      .nullable(),
    // .required(
    //   t('form.invalid.required', {
    //     fieldName: t('register.form.phone'),
    //   }),
    // )
    // address: yup.object().required(),
    password: isEdit
      ? yup
          .string()
          .nullable()
          .matches(PASSWORD_PATTERN, PASSWORD_PATTERN_ERROR_MESSAGE)
      : yup
          .string()
          .matches(PASSWORD_PATTERN, PASSWORD_PATTERN_ERROR_MESSAGE)
          .required(PASSWORD_REQUIRE_ERROR_MESSAGE),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password')], CONFIRM_PASSWORD_ERROR_MESSAGE)
      .when(['password'], ([password], schema) => {
        return password
          ? schema.required(CONFIRM_PASSWORD_ERROR_MESSAGE)
          : schema;
      }),
  });
