import { Anchor, Avatar, Tooltip } from '@mantine/core';
import { Link } from 'react-router-dom';
type PropType = {
  data?: { name?: string; src?: string; id?: string }[];
  total?: number;
};
const GroupAvatar = (props: PropType) => {
  // ### CONSTANTs
  const { data = [], total } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Avatar.Group>
      {data?.map((item, index) => {
        return index < 5 ? (
          <>
            <Tooltip label={item?.name} withArrow key={index}>
              <Anchor component={Link} to={`/system-users/${item?.id}`}>
                <Avatar
                  color="primary"
                  size={'sm'}
                  src={item?.src}
                  name={item?.name}
                />
              </Anchor>
            </Tooltip>
          </>
        ) : null;
      })}
      {total ? <Avatar size={'sm'}>+{total - data?.length}</Avatar> : null}
    </Avatar.Group>
  );
};
export default GroupAvatar;
