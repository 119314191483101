import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IconFA from 'components/common/IconFA';
import { currencyFormatter, getColor } from 'helpers/utils';
import { t } from 'i18next';
import { Form } from 'react-bootstrap';

const ShippingMethodCard = ({
  shipping,
  selectedShipping,
  setSelectedShipping,
  onRefetchShipping,
  gettingShipping,
}) => {
  const onSelectItem = (x) => {
    if (selectedShipping?.id !== x?.id) {
      setSelectedShipping?.(x);
    }
  };
  return (
    <div className="bg-100 p-3 rounded-2 mt-3">
      <div className="mb-2 d-flex align-items-center gap-1">
        <h5 className="mb-0">{t('orders.shipping_method')}</h5>
        <IconFA
          type="light"
          onClick={() => onRefetchShipping()}
          icon="arrows-rotate"
          color={getColor('gray-700')}
        />
      </div>
      {!gettingShipping ? (
        !shipping ? (
          <div className="fs--1">{t('orders.please_fetch_data')}</div>
        ) : (
          (shipping?.shipping_items || [])?.map((x) => {
            const selected =
              selectedShipping?.id && selectedShipping?.id === x?.id;
            return (
              <div
                key={x?.id}
                onClick={() => onSelectItem(x)}
                className="d-flex flex-row gap-2 justify-content-between w-100 mt-2 clickable "
              >
                <div className="d-flex flex-row align-items-center gap-2">
                  <Form.Check
                    key={x?.id}
                    id={x?.id}
                    type="radio"
                    onChange={(e) => {
                      onSelectItem(x);
                    }}
                    // checked={false}
                    checked={selected ? true : false}
                  />
                  <div className="fs-0 fw-medium">{x?.servicelevel_name}</div>
                  <div className="fs-0 text-700">
                    {x?.estimated_days || 0} {t('orders.business_days')}
                  </div>
                </div>
                <div
                  className={`fs-0 fw-medium ${selected ? 'text-primary' : ''}`}
                >
                  {currencyFormatter.format(x?.amount)}
                </div>
              </div>
            );
          })
        )
      ) : (
        <div className="d-flex flex-center flex-column">
          <FontAwesomeIcon
            size="x"
            spin
            color={getColor('gray-700')}
            icon="spinner"
            className="ms-1"
          />
          <p className="mt-2 fs--1">{t('orders.fetching_shipment')}</p>
        </div>
      )}
    </div>
  );
};
export default ShippingMethodCard;
