export const adminRoutes = [
  {
    label: 'Dashboard',
    icon: 'chart-pie',
    to: '/',
    mainAction: '/dashboards/reports',
  },
  {
    label: 'Customer service',
    icon: 'bullhorn',
    children: [
      // {
      //   label: 'Inbox',
      //   to: '/inbox',
      // },
      {
        label: 'Reviews',
        to: '/reviews',
        mainAction: '/product-reviews',
      },
      {
        label: 'Push notifications',
        to: '/notification',
      },
      {
        label: 'Information',
        to: '/information?&type=pages',
        mainAction: '/information',
      },
      {
        label: 'Help articles',
        to: '/help-articles?section=shopping_with_nailzy',
        mainAction: '/information',
      },
    ],
  },
  { label: 'Marketplace', disabled: true },
  {
    label: 'Sales',
    icon: 'shopping-cart',
    children: [
      {
        label: 'Orders',
        to: '/orders',
        mainAction: '/orders',
      },
      {
        label: 'Draft carts',
        to: '/draft-carts',
        mainAction: '/draft-carts',
      },
    ],
  },
  {
    label: 'Inventory',
    icon: 'tag',
    children: [
      {
        label: 'Products',
        to: '/products',
        mainAction: '/products',
      },
      {
        label: 'Categories',
        to: '/categories',
        mainAction: '/categories',
      },
      {
        label: 'Brands',
        to: '/brands',
        mainAction: '/brands',
      },
    ],
  },
  {
    label: 'Marketing center',
    icon: 'percentage',
    children: [
      {
        label: 'Promotions',
        to: '/promotions',
        mainAction: '/promotions',
      },
      {
        label: 'Banners',
        to: '/banners',
        mainAction: '/promotions',
      },
      {
        label: 'Flash sales',
        to: '/flash-sales',
        mainAction: '/promotions',
      },
      {
        label: 'Coupons',
        to: '/coupons',
        mainAction: '/coupons',
      },
      // {
      //   label: 'Discounts',
      //   to: '/discounts',
      // },
      // {
      //   label: 'Shipping discounts',
      //   to: '/shipping-discounts',
      // },
      // {
      //   label: 'Combo deals',
      //   to: '/mega-combos',
      // },
      // {
      //   label: 'Bonus gifts',
      //   to: '/bonus-gift',
      // },
    ],
  },
  {
    label: 'Finances',
    icon: 'dollar-sign',
    children: [
      {
        label: 'Balance',
        to: '/balance',
        mainAction: '/sellers/balances',
      },
      // {
      //   label: 'Transactions',
      //   to: '/transactions',
      // },
      // {
      //   label: 'Payouts',
      //   to: '/payouts',
      // },
    ],
  },
  {
    label: 'Users',
    icon: 'users',
    children: [
      {
        label: 'Customers',
        icon: 'users',
        to: '/customers',
        mainAction: '/customers',
      },
      {
        label: 'Sellers',
        icon: 'user-tag',
        to: '/sellers',
        mainAction: '/sellers',
      },
    ],
  },
  {
    label: 'Postings',
    disabled: true,
  },
  {
    label: 'Posts',
    icon: 'newspaper',
    children: [
      {
        label: 'Jobs',
        to: '/jobs',
        mainAction: '/jobs',
      },
      {
        label: 'Techs',
        to: '/techs',
        mainAction: '/techs',
      },
      {
        label: 'Salons',
        to: '/salons',
        mainAction: '/salons',
      },
      {
        label: 'Skills',
        to: '/skills',
        mainAction: '/skills',
      },
    ],
  },
  {
    label: 'Events',
    icon: 'user-graduate',
    to: '/events',
    mainAction: '/events',
  },
  {
    label: 'Settings',
    disabled: true,
  },
  {
    label: 'Account',
    icon: 'user-circle',
    children: [
      {
        label: 'Profile & Security',
        to: '/account',
      },
      {
        label: 'System users',
        to: '/system-users',
        mainAction: '/system-users',
      },
    ],
  },
  {
    label: 'Shipping ',
    icon: 'truck',
    children: [
      {
        label: 'Warehouse',
        to: '/warehouse',
        mainAction: '/warehouses/match',
      },
      {
        label: 'Packages',
        to: '/packages',
        mainAction: '/packages',
      },
      {
        label: 'Shipping',
        to: '/shipping',
        mainAction: '/shipping-configs',
      },
      // {
      //   label: 'Sales taxes',
      //   to: '/sales-taxes',
      // },
    ],
  },
];

export default adminRoutes;
