import React from 'react';
import getCroppedImg, { convertUrlToFile } from 'helpers/cropImage';
import { useState } from 'react';
import IconButton from './IconButton';
import { t } from 'i18next';
import IconFA from './IconFA';
import { Card, Col, Form, Image, Row, Modal } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import Cropper from 'react-easy-crop';

const CropImageModal = ({
  imgToCrop,
  onSave,
  onHide,
  cropOptions = { width: 1200, height: 1200 },
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [croppedImg, setCroppedImage] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    if (croppedAreaPixels?.width && croppedAreaPixels?.height) {
      debounceRerenderPreview(croppedAreaPixels);
    }
  };
  const debounceRerenderPreview = useAsyncDebounce((value) => {
    rerenderPreview(value);
  }, 500);
  const rerenderPreview = async (croppedAreaPixels) => {
    const croppedImage = await getCroppedImg(
      imgToCrop,
      croppedAreaPixels,
      rotate,
    );
    setCroppedImage(croppedImage);
  };

  return (
    <Modal
      show={!!imgToCrop}
      //   onHide={() => setCropImage(null)}
      onHide={() => onHide?.()}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Crop image</Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-3 p-3" style={{ height: 600 }}>
        <Row style={{}}>
          <Col md={9}>
            {imgToCrop ? (
              <Cropper
                style={{ width: '100%' }}
                image={imgToCrop}
                crop={crop}
                zoom={zoom}
                rotation={rotate}
                aspect={cropOptions?.width / cropOptions?.height}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            ) : null}
          </Col>
          <Col md={3}>
            <Card style={{ flex: 1 }}>
              <Image src={croppedImg} />
            </Card>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
          <div className="w-50 d-flex flex-row align-items-center">
            <Form.Range
              value={(zoom - 1) * 50}
              onChange={(e) => {
                setZoom((50 + parseInt(e.target.value)) / 50);
              }}
            />
            <div className="ms-2">
              <IconFA
                icon={'fa-rotate-left'}
                onClick={() => setRotate(rotate === 360 ? 0 : rotate + 90)}
              />
            </div>
          </div>
          <div className="d-flex flex-row align-items-center">
            <IconButton
              variant="falcon-default"
              transform="shrink-3"
              onClick={() => onHide?.()}
            >
              <span className="d-none d-sm-inline-block ms-1">
                {t('general.cancel')}
              </span>
            </IconButton>
            <div className="ms-2">
              <IconButton
                variant="primary"
                transform="shrink-3"
                onClick={async () => {
                  const fileFromUrl = await convertUrlToFile(croppedImg);
                  onSave?.(fileFromUrl);
                }}
              >
                <span className="d-none d-sm-inline-block ms-1">
                  {t('general.save')}
                </span>
              </IconButton>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
export default CropImageModal;
