import { ReactNode } from 'react';
import { Card } from '@mantine/core';
import classNames from 'classnames';
type PropType = {
  children: ReactNode;
  className?: string;
  stick?: boolean;
};
const SectionWrapper = (props: PropType) => {
  // ### CONSTANTs
  const { children, className, stick } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Card
      radius={'md'}
      shadow="md"
      className={classNames(
        'gap-3 p-4 mb-3',
        stick ? 'sticky-sidebar top-navbar-height' : '',
        className,
      )}
    >
      {children}
    </Card>
  );
};
export default SectionWrapper;
