import { Badge, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { getColor } from 'helpers/utils';
import { OrderStatusEnum, OrderStatuses } from 'types';
import { t } from 'i18next';

type Props = {
  status: OrderStatusEnum;
  fullWidth?: boolean;
};
const OrderStatus = ({
  status = OrderStatusEnum.CANCELED,
  fullWidth = false,
}: Props) => {
  const statusConfig = OrderStatuses?.[status];
  return (
    <div style={{ alignSelf: 'flex-start' }}>
      <Badge
        fullWidth={fullWidth}
        variant="light"
        color={getColor(statusConfig?.bg)}
        leftSection={
          <IconFA
            icon={statusConfig?.icon}
            color={getColor(statusConfig?.bg)}
          />
        }
      >
        <Text fw={600} size="xs">
          {t(statusConfig?.label)}
        </Text>
      </Badge>
    </div>
  );
};

export default OrderStatus;
