import FalconCloseButton from 'components/common/FalconCloseButton';
import PropTypes from 'prop-types';
import { ReactNode, forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Text } from '@mantine/core';
import { SectionFooter } from '../CommonSection';
type Props = {
  remove?: any;
  name?: ReactNode;
  onShow?: any;
  onClose?: any;
  onDelete?: any;
  isLoading?: any;
  title?: any;
};

const DeleteModal = forwardRef((props: Props, ref) => {
  const {
    remove,
    name = 'this item',
    onShow,
    onClose: onModalClose,
    onDelete,
    isLoading = false,
    title,
  } = props;
  const { t } = useTranslation();
  const [internalShow, setInternalShow] = useState(false);
  const [product, setProduct] = useState<any>(null);
  useImperativeHandle(ref, () => ({
    show,
    hide,
  }));
  const show = (product: any) => {
    setInternalShow(true);
    setProduct(product);
  };
  const hide = (product: any) => {
    setInternalShow(false);
  };
  const action = (() => {
    if (remove && isLoading) return t('general.removing');
    if (remove && !isLoading) return t('general.remove');
    if (!remove && isLoading) return t('general.deleting');
    if (!remove && !isLoading) return t('general.delete');
  })();
  const onClose = () => {
    setInternalShow(false);
    onModalClose?.();
  };
  return (
    <Modal
      opened={internalShow || onShow}
      onClose={onClose}
      centered
      size={'md'}
      title={
        title ? (
          title
        ) : (
          <Text>
            {remove ? t('general.remove') : t('general.delete')}{' '}
            {product?.name || name}?
          </Text>
        )
      }
    >
      {t('general.delete_confirm')}{' '}
      {remove
        ? t('general.remove').toLowerCase()
        : t('general.delete').toLowerCase()}{' '}
      <strong className="text-danger">{product?.name || name}</strong>
      {t('general.cant_undone')}
      <SectionFooter>
        <Button
          color={'red'}
          onClick={() => onDelete?.(product)}
          loading={isLoading}
        >
          {action}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t('general.cancel')}
        </Button>
      </SectionFooter>
    </Modal>
  );
});

export default DeleteModal;
