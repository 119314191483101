import {
  Avatar,
  AvatarProps,
  MantineRadius,
  MantineSize,
  MantineStyleProp,
} from '@mantine/core';
import { UserInfo } from 'types';
type PropType = AvatarProps & {
  className?: string;
  user?: UserInfo;
  src?: string;
  size?: MantineSize | number;
  radius?: MantineRadius;
  onChange?: () => void;
};
const CommonAvatar = (props: PropType) => {
  // ### CONSTANTs
  const { className = '', user, src, size = 'md', radius = 'xl' } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <Avatar
      color="primary"
      radius={radius}
      size={size}
      src={src || user?.profile_image_url}
      className={`${className} border`}
      name={`${user?.first_name} ${user?.last_name}`}
    />
  );
};
export default CommonAvatar;
