const vi = {
  translation: {
    admin_panel: 'Trang quản trị',
    seller_panel: 'Trang bán hàng',
    error: {
      error_404: 'Lỗi 404',
      error_404_message: 'Trang bạn đang truy cập hiện không tồn tại.',
      error_404_content:
        'Vui lòng kiểm tra lại đường liên kết. Nếu lỗi vẫn xảy ra,',
      contact_us: 'nhờ trợ giúp.',
      back_home: 'Quay lại trang chính',
      error_403: 'Error 403',
      error_403_message: 'Truy cập bị từ chối.',
      error_403_content:
        'Bạn không có quyền truy cập vào trang này. Nếu lỗi vẫn xảy ra,',
      error_500: 'Error 500',
      error_500_message: ' Đã có lỗi xảy ra ở đâu đó.',
      error_500_content: 'Vui lòng thử lại sau. Nếu lỗi vẫn xảy ra,',
    },
    navigation: {
      home: 'Trang chính',
      language: 'English',
      help_center: 'Hỗ trợ',
      carts: 'Giỏ hàng',
      cart_details: 'Chi tiết giỏ hàng',
      edit_cart: 'Sửa giỏ hàng',
      add_cart: 'Thêm giỏ hàng',
      promotions: 'Khuyến mãi',
      coupons: 'Mã giảm giá',
      coupon_details: 'Chi tiết mã giảm giá',
      edit_coupon: 'Sửa mã giảm giá',
      add_coupon: 'Thêm mã giảm giá',
      announcements: 'Thông báo',
      banners: 'Banners',
      banner_details: 'Chi tiết banner',
      edit_banner: 'Sửa banner',
      flash_sale: 'Giảm giá siêu tốc',
      stock_clearance: 'Hàng thanh lý',
      just_arrived: 'Hàng mới về',
      top_picks: 'Hàng chuẩn',
      discounts: 'Giảm giá',
      discount_details: 'Chi tiết giảm giá',
      edit_discount: 'Sửa giảm giá',
      customers: 'Khách hàng',
      users: 'Người dùng',
      customer_details: 'Chi tiết khách hàng',
      user_details: 'Chi tiết người dùng',
      sellers: 'Người bán',
      seller_details: 'Chi tiết người bán',
      postings: 'Bài đăng',
      posts: 'Bài viết',
      jobs: 'Việc làm',
      techs: 'Hồ sơ thợ',
      salons: 'Tiệm cần bán',
      skills: 'Kỹ năng',
      events: 'Khóa học',
      event_signups: 'Đăng ký khóa học',
      settings: 'Thiết lập',
      staff: 'Nhân viên',
      staff_details: 'Chi tiết nhân viên',
      shipping: 'Vận chuyển',
      taxes: 'Thuế',
      pages: 'Trang',
      information: 'Thông tin',
      faqs: 'Câu hỏi thường gặp',
      warehouses: 'Kho hàng',
      help_articles: 'Bài viết hỗ trợ',
      articles: 'Bài viết',
      current: 'Hiện tại',
    },
    register: {
      form: {
        first_name: 'Tên',
        last_name: 'Họ',
        store_name: 'Tên cửa hàng',
        store_logo: 'Logo cửa hàng',
        store_address: 'Địa chỉ cửa hàng',
        store_phone: 'Điện thoại cửa hàng',
        store_email: 'Email cửa hàng',
        phone: 'Điện thoại',
        mobile: 'Di động',
        address: 'Địa chỉ',
        email: 'Email',
        password: 'Mật khẩu',
        department: 'Phòng',
        confirm_password: 'Xác nhận mật khẩu',
        new_password: 'Mật khẩu mới',
        confirm_new_password: 'Xác nhận mật khẩu mới',
        current_password: 'Mật khẩu hiện tại',
        verification_code: 'Mã xác thực',
        account_registration: 'Tài khoản',
        seller_information: 'Thông tin người bán',
        email_verification: 'Xác thực email',
        done: 'Hoàn tất',
        register_content: `Vui lòng cung cấp thông tin tài khoản của bạn.`,
        read_agree: `Tôi xác nhận đã đọc và đồng ý với`,
        seller_agreement: 'Chính sách bán hàng',
        verify_email: 'Xác thực email',
        create_account: 'Tạo tài khoản',
        back: 'Quay lại',
        please_otp: 'Vui lòng nhập 4 chữ số mã xác thực đã được gửi tới email',
        didnt_otp: `Không nhận được mã?`,
        resend_otp: 'Gửi lại',
        thank_you: 'Cảm ơn bạn đã tham gia bán hàng cùng Nailzy!',
        our_team:
          'Nhân viên của Nailzy sẽ sớm liên lạc với bạn qua điện thoại hoặc email để giúp bạn hoàn tất thủ tục đăng ký tài khoản. Hãy chờ nhé!',
        business_type: 'Loại hình kinh doanh',
        company: 'Công ty',
        individual: 'Cá nhân',
        legal_business_name: 'Tên pháp lý công ty',
        tax_id: 'Mã số thuế (EIN ITIN, hoặc SSN)',
        business_email: 'Email doanh nghiệp',
        email_existed: 'Email đã tồn tại',
      },
      sell_on: 'Mở tiệm bán hàng trên sàn Nailzy.com',
      become_our: 'Trở thành đối tác',
      selling_partner: 'bán hàng của Nailzy',
      create_a: 'Đăng ký tài khoản bán hàng',
      free: 'MIỄN PHÍ',
      seller_account: 'chỉ với vỏn vẹn',
      simple: '3 bước đơn giản',
      to_sell: 'để bắt đầu đăng sản phẩm và nhận thanh toán.',
      why_sell: 'Tại sao bán nên bán hàng cùng Nailzy?',
      why_sell_content:
        'Nailzy.com là siêu ứng dụng cung cấp các giải pháp tối ưu cho ngành nail: giúp tiệm tìm thợ, thợ tìm việc, và sang nhượng tiệm nail nhanh chóng. Sàn thương mại điện tử Nailzy được ví như là Amazon của ngành nail, đem đến cho các nhà bạn một nền tảng bán hàng hiện đại và tiện lợi nhất.',
      fast: 'Tham gia nhanh và miễn phí',
      fast_content:
        'Chúng tôi đem đến một giải pháp tiện lợi để mở tiệm bán hàng trực tuyến trên Nailzy, với cách thức đăng ký hoàn toàn miễn phí và dễ dàng.',
      increased: 'Mức hiển thị và độ tiếp cận cao',
      increased_content:
        'Đăng bán hàng trên Nailzy giúp tăng khả năng hiển thị và tiếp cận đến nhiều đối tượng khách hàng chủ chốt của ngành nail.',
      easy: 'Dễ sử dụng',
      easy_content:
        'Hệ thống giúp quản lý sản phẩm và xử lý đơn hàng mọi lúc mọi nơi trên bất kỳ thiết bị nào với giao diện đơn giản và dễ sử dụng.',
      marketing: 'Tiếp thị & quảng cáo',
      marketing_content:
        'Tăng doanh số bán hàng qua các chương trình khuyến mãi và quảng cáo để tiếp cận với nhiều đối tượng khách hàng.',
      customer: 'Hỗ trợ chăm sóc khách hàng',
      customer_content:
        'Dịch vụ hỗ trợ và chăm sóc khách hàng xuất sắc, với đội ngũ tận tâm với nền tảng kiến thức sâu luôn sẵn sàng giúp đỡ người bán.',
      unique: 'Tính năng độc đáo',
      unique_content:
        'Ứng dụng Nailzy cung cấp nhiều tính năng cho nghề nail như tìm việc, tuyển thợ, sang nhượng tiệm, hoặc lớp dạy nail.',
      how_to_sell: 'Các bước để bán hàng trên sàn Nailzy',
      step_1: 'Mở tài khoản bán hàng',
      step_1_1: 'Điền thông tin tài khoản',
      step_1_2: 'Cung cấp các giấy tờ kinh doanh',
      step_1_3: 'Được chấp thuận và bắt đầu bán hàng',
      step_2: 'Đăng sản phẩm',
      step_2_1: 'Đăng từng sản phẩm',
      step_2_2: 'Đăng sản phẩm hàng loạt',
      step_3: 'Quản lý đơn hàng',
      step_3_1: 'Nhận email thông báo khi có đơn hàng mới',
      step_3_2: 'Xử lý đơn hàng và in nhãn vận chuyển',
      step_3_3: 'Giao hàng cho đơn vị vận chuyển',
      step_4: 'Nhận thanh toán',
      step_4_1: 'Nhận báo cáo doanh thu hàng tháng',
      step_4_2: 'Nhận thanh toán qua chuyển khoản hoặc chi phiếu',
      contact: 'Liên hệ hỗ trợ bán hàng',
      calculate: 'Cách tính phí bán hàng',
      there_are: 'Bạn sẽ',
      zero_fees: 'KHÔNG bị tính phí sử dụng',
      and_only: 'và chỉ phải chịu',
      for_your: 'trong 3 tháng đầu tiên bán hàng trên sàn Nailzy!',
      after_that: `Sau đó chúng tôi sẽ thu`,
      platform: '10% phí sử dụng nền tảng',
      and: 'và',
      transaction: '3% phí giao dịch',
      each_order: 'cho mỗi đơn hàng. ',
      the_fees:
        'Những phí này sẽ được tính dựa trên tổng doanh thu của bạn sau khi đã trừ đi các khoản giảm giá, tiền vận chuyển, và thuế nếu có.',
      subtotal: 'Tiền hàng',
      discount: 'Giảm giá',
      shipping: 'Vận chuyển',
      sales_tax: 'Thuế',
      order_total: 'Tổng tiền hàng',
      platform_fee: 'Phí sử dụng nền tảng 10%',
      transaction_fee: 'Phí giao dịch 3%',
      your_payout: 'Số tiền bạn nhận',
      net_sales: 'Doanh thu ròng',
    },
    login: {
      logout: 'Đăng xuất',
      register: 'Đăng ký',
      login: 'Đăng nhập',
      admin_login: 'Nhân viên đăng nhập',
      admin_please: 'Vui lòng đăng nhập để truy cập vào trang quản trị.',
      seller_login: 'Người bán đăng nhập',
      seller_please: 'Vui lòng đăng nhập để truy cập vào trang bán hàng.',
      forgot_password: 'Quên mật khẩu?',
      no_account: `Chưa có tài khoản bán hàng?`,
      register_free: 'Đăng ký miễn phí',
      already_seller: 'Đã có tài khoản?',
      signed_out: 'Đã đăng xuất',
      thank_you: 'Cảm ơn bạn đã sử dụng dịch vụ của Nailzy!',
      back_login: 'Quay lại trang đăng nhập',
      login_with_password: 'Đăng nhập dùng mật khẩu',
      login_without_password: 'Đăng nhập không cần mật khẩu',
      enter_verification_code: 'Vui lòng nhập mã xác nhận',
      code_sent_to: 'Một mã đã được gửi tới',
      or_continue_with: 'Hoặc tiếp tục bằng',
    },
    forgot_password: {
      forgot_password: 'Quên mật khẩu',
      reset_password: 'Đặt lại mật khẩu',
      please_enter: 'Vui lòng nhập địa chỉ email của bạn để nhận mã xác thực.',
      send_code: 'Gửi mã xác thực',
      please_code: 'Vui lòng nhập 4 chữ số mã xác thực đã được gửi tới email',
      create_new: 'và tạo mật khẩu mới.',
      password_reset: 'Mật khẩu đã được đặt lại!',
      you_can: 'Giờ bạn có thể',
      login_to: 'đăng nhập vào tài khoản',
      a_mail_sent_to_reset_password:
        'Vui lòng kiểm tra hộp thư của bạn để đặt lại mật khẩu',
    },
    account: {
      account: 'Tài khoản',
      store_details: 'Thông tin cửa hàng',
      sales_rep_code: 'Mã nhân viên',
      bank_details: 'Thông tin ngân hàng',
      bank_name: 'Ngân hàng',
      account_name: 'Tên chủ tài khoản',
      account_number: 'Số tài khoản',
      routing_number: 'Mã ngân hàng',
      security: 'Bảo mật',
      change_password: 'Đổi mật khẩu',
      security_desc: 'Bạn có thể thêm hoặc thay đổi phương thức đăng nhập.',
      social_links: 'Liên kết mạng xã hội',
      social_links_desc:
        'Liên kết tài khoản với các mạng xã hội để đăng nhập nhanh hơn.',
      profile_security: 'Hồ sơ & Bảo mật',
      store_desc: 'Vui lòng cung cấp thông tin cửa hàng của bạn.',
    },
    dashboard: {
      dashboard: 'Bảng điều khiển',
      welcome: 'Xin chào',
      greeting: 'Tổng quan về hoạt động hôm nay',
      today_sales: 'Doanh thu hôm nay',
      today_orders: 'Đơn hàng hôm nay',
      total_products: 'Tổng số sản phẩm',
      total_products_desc: 'Tổng số sản phẩm đăng bán',
      total_orders: 'Tổng đơn hàng',
      total_orders_desc: 'Tổng số đơn hàng đã thanh toán',
      total_payouts: 'Lợi  nhuận',
      total_payouts_desc: 'Tổng số tiền bạn đã nhận được',
      total_customers: 'Tài khoản mới',
      total_customers_desc: 'Tổng số khách hàng đã đăng ký tài khoản mới',
      total_jobs: 'Tổng số việc làm',
      total_jobs_desc: 'Tổng số việc làm đã đăng',
      total_techs: 'Tổng số hồ sơ thợ',
      total_techs_desc: 'Tổng số hồ sơ thợ đã đăng',
      total_salons: 'Tổng số tiệm đăng bán',
      total_salons_desc: 'Tổng số tiệm đã đăng bán',
      total_events: 'Tổng số sự kiện',
      total_events_desc: 'Tổng số sự kiện đã đăng',
      best_selling: 'Sản phẩm bán chạy nhất',
      items_sold: 'Sản phẩm đã bán',
      items_sold_decs: 'Số lượng sản phẩm đã bán ra',
      unit_price: 'Đơn giá',
      total_sales: 'Tổng doanh thu',
      total_sales_desc: 'Tổng số tiền hàng đã thanh toán',
      total_posts: 'Tổng số bài đăng',
      store_visits: 'Lượt truy cập',
      store_visits_decs: 'Số lượng người truy cập vào cửa hàng',
      product_views: 'Lượt xem sản phẩm',
      product_views_decs: 'Tổng số lượng lượt xem các sản phẩm',
      conversion_rate: 'Tỷ lệ chuyển đổi',
      conversion_rate_decs:
        'Tỷ lệ chuyển đổi từ lượt xem sản phẩm sang đơn hàng',
      thing_to_do: 'Việc cần làm',
      need_accept: 'Đơn hàng đang chờ xác nhận',
      waiting_payment: 'Đơn hàng đang chờ thanh toán',
      need_fulfill: 'Đơn hàng đang chờ đóng hàng',
      need_approve: 'Đánh giá sản phẩm đang chờ duyệt',
      best_seller_items: 'Sản phẩm bán chạy',
      notifications: 'Thông báo',
      marketing_center: 'Trung tâm tiếp thị',
      last_x_day: '{{days}} ngày gần đây',
      custom: 'Tùy chỉnh',
      total_paid_orders: 'Đơn hàng đã thanh toán',
      total_paid_orders_desc: 'Tổng số đơn hàng đã thanh toán',
      total_paid_sales: 'Tổng tiền đã thanh toán',
      total_paid_sales_desc: 'Tổng giá trị đơn hàng đã thanh toán',
      total_unpaid_orders: 'Tổng số đơn hàng chưa thanh toán',
      total_unpaid_orders_desc:
        'Đơn hàng chưa thanh toán (bao gồm đơn hàng chờ thanh toán, thanh toán thất bại, và chưa thanh toán)',
      total_unpaid_sales: 'Tổng tiền chưa thanh toán',
      total_unpaid_sales_desc:
        'Tổng giá trị đơn hàng chưa thanh toán (bao gồm đơn hàng chờ thanh toán, thanh toán thất bại, và chưa thanh toán)',
      order_analytics: 'Thống kê đơn hàng',
      customer_analytics: 'Thống kê khách hàng',
      total_buyer: 'Tổng số khách hàng',
      total_buyer_desc: 'Tổng số khách đã mua hàng',
      total_returning_buyer: 'Khách hàng trở lại',
      total_returning_buyer_desc: 'Tổng số khách đã mua hàng nhiều hơn một lần',
      total_potential_buyer: 'Khách hàng tiềm năng',
      total_potential_buyer_desc:
        'Tổng số khách đã đặt hàng nhưng chưa bao giờ thanh toán',
    },
    general: {
      at: 'lúc',
      all: 'Tất cả',
      search: 'Tìm kiếm...',
      start_date: 'Từ',
      end_date: 'Đến',
      created_date: 'Ngày tạo',
      created_by: 'Tạo bởi',
      updated_date: 'Ngày cập nhật',
      published_date: 'Ngày đăng',
      status: 'Trạng thái',
      active: 'Hoạt động',
      draft: 'Nháp',
      sent: 'Đã gửi',
      canceled: 'Đã hủy',
      scheduled: 'Đã lên lịch',
      deleted: 'Đã xóa',
      closed: 'Đã đóng',
      approved: 'Đã duyệt',
      pending: 'Đang chờ',
      rejected: 'Đã từ chối',
      completed: 'Hoàn tất',
      previous: 'Trước',
      next: 'Tiếp',
      rows_per_page: 'Dòng mỗi trang',
      from: 'từ',
      to: 'đến',
      of: 'của',
      selected: 'đã chọn',
      bulk_actions: 'Thao tác hàng loạt',
      change_status: 'Đổi trạng thái',
      apply: 'Áp dụng',
      applying: 'Đang áp dụng...',
      edit: 'Sửa',
      delete: 'Xóa',
      deleting: 'Đang xóa...',
      remove: 'Bỏ',
      removing: 'Đang bỏ...',
      export: 'Xuất dữ liệu',
      exporting: 'Đang xuất...',
      import: 'Nhập dữ liệu',
      importing: 'Đang nhập...',
      cancel: 'Hủy',
      discard: 'Loại bỏ',
      close: 'Đóng',
      save: 'Lưu',
      saving: 'Đang lưu...',
      reset: 'Đặt lại',
      view: 'Xem',
      export_desc: 'Bạn có muốn xuất dữ liệu của',
      export_desc_2: 'ra tệp bảng tính (định dạng XLSX)?',
      import_desc: 'hàng loạt',
      import_desc_2:
        ' mẫu bảng tính (định dạng XLSX) và điền đầy đủ thông tin cần thiết.',
      import_desc_3:
        'Sau đó bạn có thể tải tệp XLXS của bạn lên để nhập hàng loạt',
      upload_import: 'Tải lên để nhập',
      download: 'Tải về',
      upload_images: 'Tải ảnh lên',
      upload_images_desc:
        'Bạn có thể tải lên nhiều ảnh cùng lúc bằng tệp định dạng ZIP.',
      upload_images_desc_2:
        'Tên tệp ảnh phải tương ứng với dữ liệu của cột "Tên tệp ảnh" trong mẫu nhập dữ liệu hàng loạt.',
      upload: 'Tải lên',
      uploading: 'Đang tải lên...',
      notes: 'Ghi chú',
      add_note: 'Thêm ghi chú',
      edit_note: 'Sửa ghi chú',
      content: 'Nội dung',
      type_here: 'Nhập vào đây...',
      delete_confirm: 'Bạn có chắc chắn muốn ',
      delete_confirm_2: 'Bạn có chắc chắn muốn xóa',
      cant_undone: '? Thao tác này không thể hoàn lại.',
      filter: 'Bộ lọc',
      sort: 'Sắp xếp',
      view_web: 'Xem trên web',
      images: 'Hình ảnh',
      browse: 'Chọn',
      images_desc: 'hoặc kéo thả ảnh vào đây',
      images_desc_2: 'Tối đa 10 ảnh',
      discard_all: 'Loại bỏ thay đổi chưa lưu',
      discard_all_desc:
        'Bạn có chắc chắn muốn loại bỏ những thay đổi chưa được lưu? Dữ liệu mà bạn đã nhập sẽ bị mất.',
      name_asc: 'Tên: A đến Z',
      name_desc: 'Tên: Z đến A',
      code_asc: '#: A đến Z',
      code_desc: '#: Z đến A',
      rating_desc: 'Đánh giá: Cao nhất trước',
      rating_asc: 'Đánh giá: Thấp nhất trước',
      created_desc: 'Ngày tạo: Mới nhất trước',
      created_asc: 'Ngày tạo: Cũ nhất trước',
      updated_desc: 'Ngày cập nhật: Mới nhất trước',
      updated_asc: 'Ngày cập nhật: Cũ nhất trước',
      reorder: 'Sắp xếp',
      update_success: 'Cập nhật thành công!',
      update_fail: 'Cập nhật thất bại!',
      create_success: 'Tạo thành công!',
      create_fail: 'Tạo thất bại!',
      slug_exist: 'Slug đã tồn tại',
      arrange: 'Sắp xếp',
      add: 'Thêm',
      name: 'Tên',
      image: 'Hình ảnh',
      slug: 'Slug',
      icon: 'Icon',
      back: 'Quay lại',
      select: 'Chọn',
      continue: 'Tiếp tục',
      copied: 'Đã sao chép',
      copy_failed: 'Sao chép thất bại',
      write: 'Viết',
      update: 'Cập nhật',
      confirm: 'Xác nhận',
      description: 'Mô tả',
      official_brand: 'Official brand',
      generate_code: 'Tạo mã',
      upload_and_save: 'Tải lên và lưu',
      main_image: 'Hình ảnh chính',
      added: 'Đã thêm',
      link: 'Liên kết',
      unlink: 'Huỷ liên kết',
      profile_picture: 'Ảnh đại diện',
      banner: 'Banner',
      drop_files: 'Kéo thả tệp vào đây',
      code: 'Mã',
      type: 'Loại',
      percentage: 'Phần trăm',
      fixed: 'Cố định',
      value: 'Giá trị',
      create: 'Tạo',
      select_icon: 'Chọn icon',
      select_date_time: 'Chọn ngày và giờ',
      select_all: 'Chọn tất cả',
      validity_period: 'Thời hạn hiệu lực',
      quantity: 'Số lượng',
      verify: 'Xác thực',
      verifying: 'Đang xác thực...',
      no_data: 'Chưa có dữ liệu...',
      phone: 'Số điện thoại',
      view_more: 'Xem thêm',
      view_less: 'Thu gọn',
      edit_history: 'Lịch sử chỉnh sửa',
      by: 'bởi',
      delete_success: 'Xoá thành công!',
      delete_fail: 'Xoá thất bại!',
      days_ago: 'ngày trước',
      last_update_on: 'Cập nhật lần cuối',
      joined_on: 'Đã tham gia',
      month_ago: 'tháng trước',
      title: 'Tiêu đề',
      to_be_confirmed: 'Cần xác nhận',
      select_date_range: 'Chọn khoảng thời gian',
      select_payment_status: 'Chọn trạng thái thanh toán',
      select_order_status: 'Chọn trạng thái đơn hàng',
      select_role: 'Chọn chức danh',
      page: 'Trang',
      limit: 'Giới hạn',
      export_success: 'Xuất dữ liệu thành công!',
      export_fail: 'Xuất dữ liệu thất bại!',
      import_success: 'Nhập dữ liệu thành công!',
      import_fail: 'Nhập dữ liệu thất bại!',
      make_as_default: 'Đặt làm mặt định',
      default: 'Mặc định',
      featured: 'Nổi bật',
      select_type: 'Chọn loại',
      ships_from_nailzy: 'Gửi hàng từ Nailzy',
      current: 'Hiện tại',
      change: 'Đổi',
      duplicate: 'Sao chép',
      dropzone_decs_1: 'Kéo thả ảnh hoặc bấm vào đây để chọn',
      dropzone_decs_2:
        'Có thể nhận nhiều file cùng lúc, mỗi file không quá 10mb',
      team: 'Đội',
    },
    push_notifications: {
      push_notifications: 'Thông báo đẩy',
      send_notification: 'Gửi thông báo',
      title: 'Tiêu đề',
      message: 'Nội dung',
    },
    orders: {
      marketplace: 'Mua sắm',
      sales: 'Bán hàng',
      orders: 'Đơn hàng',
      order: 'Đơn hàng',
      order_details: 'Chi tiết đơn hàng',
      edit_order: 'Sửa đơn hàng',
      no_order: 'Chưa có đơn hàng...',
      order_date: 'Ngày đặt hàng',
      order_date_range: 'Khoảng thời gian',
      customer: 'Khách hàng',
      total: 'Tổng cộng',
      quantity: 'Số lượng',
      shipping: 'Vận chuyển',
      payment_status: 'Trạng thái thanh toán',
      order_status: 'Trạng thái đơn hàng',
      fulfillment_status: 'Trạng thái đóng hàng',
      view_order: 'Xem đơn hàng',
      fulfill_order: 'Xuất đơn hàng',
      fulfill_type: 'Loại lưu kho',
      refulfill_order: 'Xuất hàng lại',
      accept: 'Xác nhận',
      buy_shipping_label: 'Mua nhãn vận chuyển',
      print_invoice: 'In hóa đơn',
      print_packing_list: 'In danh sách đóng gói',
      send_order_status: 'Gửi trạng thái đơn hàng qua SMS',
      items: 'Món',
      items_name: 'Sản phẩm',
      item: 'Món',
      paid: 'Đã thanh toán',
      unpaid: 'Chưa thanh toán',
      awaiting_payment: 'Chờ thanh toán',
      partially_paid: 'Thanh toán một phần',
      payment_failed: 'Thanh toán thất bại',
      refunded: 'Đã hoàn tiền',
      refund: 'Hoàn tiền',
      reason: 'Lý do',
      pending: 'Chưa xử lý',
      awaiting_fulfillment: 'Đang chờ đóng hàng',
      partially_shipped: 'Đã giao hàng một phần',
      shipped: 'Đã giao hàng',
      completed: 'Đã hoàn tất',
      canceled: 'Đã hủy',
      store: 'Cửa hàng',
      change_status: 'Đổi trạng thái',
      select_customer: 'Chọn khách hàng',
      select_coupons: 'Chọn mã giảm giá',
      select_status: 'Chọn trạng thái',
      send_payout: 'Gửi thanh toán',
      product: 'Sản phẩm',
      unit_price: 'Đơn giá',
      amount: 'Số tiền',
      variant: 'Lựa chọn',
      sku: 'Mã sản phẩm',
      subtotal: 'Tạm tính',
      sales_tax: 'Thuế',
      all_sellers: 'Tất cả người bán',
      discounts: 'Giảm giá',
      taxes: 'Thuế',
      grand_total: 'Tổng tiền',
      order_comments: 'Ghi chú đơn hàng',
      payment_method: 'Phương thức thanh toán',
      shipping_method: 'Phương thức vận chuyển',
      please_fetch_data: 'Vui lòng lấy dữ liệu',
      billing_address: 'Địa chỉ thanh toán',
      shipping_address: 'Địa chỉ giao hàng',
      address_book: 'Địa chỉ',
      flat_rate: 'Giá cố định',
      order_all: 'Tất cả',
      order_pending: 'Chờ tiếp nhận',
      order_processing: 'Đang xử lý',
      order_partially_shipped: 'Đã chuyển một phần',
      order_shipped: 'Đang vận chuyển',
      order_delivered: 'Đã giao hàng',
      fail_delivered: 'Giao hàng thất bại',
      order_complete: 'Đã hoàn tất',
      order_canceled: 'Đã huỷ',
      process_order: 'Xử lý đơn hàng',
      items_total: 'Tổng giá sản phẩm',
      items_discount: 'Giảm giá sản phẩm',
      coupon_discount: 'Mã giảm giá',
      dup_coupon_code: 'Nhân bản mã giảm giá',
      total_pricing: 'Tổng cộng',
      order_process: 'Tiến độ đơn hàng',
      est_delivery: 'Dự kiến giao hàng',
      business_days: 'ngày làm việc',
      business_address: 'Công ty',
      residential_address: 'Nhà riêng',
      you_want_accept_order: 'Bạn muốn tiếp nhận đơn hàng này?',
      accept_order: 'Tiếp nhận đơn hàng',
      accept_shipping: 'Xác nhận giao hàng',
      send_noti_to_customer: 'Gửi thông báo đến khách hàng',
      you_want_cancel_order: 'Bạn muốn huỷ đơn hàng này?',
      cancel_order: 'Hủy đơn hàng',
      cancel_and_refund: 'Huỷ và hoàn tiền',
      order_number: 'Đơn hàng #',
      seller_coupon: 'Mã giảm giá người bán',
      nailzy_coupon: 'Mã giảm giá Nailzy',
      package_info: 'Chi tiết thùng',
      tracking_number: 'Mã vận đơn',
      size: 'Kích thước',
      mass: 'Khối lượng',
      actions: 'Thao tác',
      shipping_information: 'Thông tin vận chuyển',
      already_bought_label: 'Bạn đã mua nhãn vận chuyển này vào',
      already_chose_label: 'Bạn đã chọn mức giá vận chuyển này vào',
      already_entered_custom: 'Bạn đã nhập đóng hàng tùy chỉnh bên dưới vào',
      business_day: 'ngày làm việc',
      void_label: 'Hủy nhãn vận chuyển',
      print_shipping_label: 'In nhãn vận chuyển',
      already_entered_tracking_info: 'Bạn đã nhập thông tin mã vận đơn này vào',
      edit_tracking_info: 'Chỉnh sửa thông tin mã vận đơn',
      enter_tracking_info: 'Nhập thông tin mã vận đơn',
      enter_custom_fulfillment: 'Nhập đóng hàng tùy chỉnh',
      package_and_weight: 'Bao bì và khối lượng',
      package: 'Bao bì',
      total_weight_with_package: 'Tổng khối lượng (cả bao bì)',
      manage_package: 'Quản lý gói hàng',
      shipping_rates: 'Mức giá vận chuyển',
      shipping_label: 'Nhãn vận chuyển',
      fetching_shipment: 'Đang tìm mức giá vận chuyển...Vui lòng đợi!',
      fetching_pricing: 'Đang tính toán tổng tiền...Vui lòng đợi!',
      summary: 'Tóm tắt',
      please_provide_shipping: 'Vui lòng cung cấp thông tin vận chuyển',
      enter_order_note: 'Nhập ghi chú đơn hàng',
      packing_list_will_print:
        'Danh sách đóng gói sẽ được in ngay sau khi bấm xác nhận',
      shipping_carrier: 'Hãng vận chuyển',
      edit_history: 'Lịch sử chỉnh sửa',
      provider: 'Provider',
      products: 'Sản phẩm',
      edit_shipping: 'Sửa thông tin mã vận đơn',
      fulfilling_shipment: 'Đang  đơn hàng...Xin vui lòng đợi!',
      write_comment: 'Viết ghi chú',
      choose_shipping_rate: 'Chọn mức giá vận chuyển',
      edit_shipping_rate: 'Sửa mức giá vận chuyển',
      customer_selected_method: 'Khách đã chọn loại này lúc thanh toán',
      fraud_analysis: 'Phân tích gian lận',
      high_risk: 'Rủi ro cao',
      normal_risk: 'Rủi ro bình thường',
      payment_information: 'Thông tin thanh toán',
      void_information: 'Hủy thông tin mã vận đơn',
      out_of_stock: 'Hết hàng',
      customer_request: 'Khách hàng yêu cầu',
      payment_error: 'Lỗi thanh toán',
      other: 'Khác',
      abandoned_checkout: 'Đơn hàng bị bỏ',
      custom_note: 'Ghi chú',
      edit_shipping_information: 'Chỉnh sửa thông tin vận chuyển',
      sync_tracking_status: 'Đồng bộ hóa trạng thái theo dõi',
      mark_as_delivered: 'Đánh dấu đơn hàng là đã giao',
      custom_fulfillment: 'Đóng hàng tùy chỉnh',
      edit_custom_fulfillment: 'Sửa đóng hàng tùy chỉnh',
      void_custom_fulfillment: 'Hủy đóng hàng tùy chỉnh',
      shipment: 'Loại vận chuyển',
      select_shipment: 'Chọn loại đóng hàng',
      shipment_manual: 'Đã nhập thông tin mã vận đơn',
      shipment_shippo: 'Đã mua nhãn vận chuyển',
      shipment_custom: 'Đã nhập đóng hàng tùy chỉnh',
      export_orders: 'Xuất đơn hàng',
      current_page: 'Trang hiện tại',
      select_items: 'Chọn {{total}} sản phẩm',
      order_success: 'Đặt hàng thành công',
      payment_success: 'Thanh toán thành công',
      order_without_paying: 'Tạo đơn hàng và thanh toán sau',
      pay_with_credit: 'Thanh toán bằng thẻ',
      send_payment_link: 'Gửi link cho khách thanh toán',
      please_select_shipping: 'Vui lòng chọn phương thức vận chuyển',
      minimum_place_order: 'Đơn đặt hàng tối thiểu là $0.5',
      source_web: 'Trang web',
      source_app: 'Ứng dụng di động',
      source_admin: 'Trang quản trị',
      source_from: 'Nguồn đặt hàng',
    },
    payouts: {
      finances: 'Tài chính',
      balance: 'Số dư',
      balance_details: 'Số dư theo đơn hàng',
      balance_seller: 'Người bán',
      payouts: 'Thanh toán',
      pay: 'Thanh toán',
      payout: 'Thanh toán',
      payout_details: 'Thanh toán cho đơn hàng',
      no_payout: 'Chưa có thanh toán...',
      completed_date: 'Ngày hoàn tất',
      platform_fee: 'Phí sử dụng nền tảng 10%',
      transaction_fee: 'Phí giao dịch 3%',
      net: 'Số tiền nhận được',
      paid: 'Đã thanh toán',
      transaction_id: 'Mã giao dịch',
      paid_date: 'Ngày thanh toán',
    },
    products: {
      inventory: 'Kho hàng',
      products: 'Sản phẩm',
      product: 'Sản phẩm',
      product_details: 'Chi tiết sản phẩm',
      edit_product: 'Sửa sản phẩm',
      add_product: 'Thêm sản phẩm',
      categories: 'Danh mục',
      category: 'Danh mục',
      subcategories: 'Danh mục con',
      subcategory: 'Danh mục con',
      brands: 'Thương hiệu',
      brand: 'Thương hiệu',
      stores: 'Cửa hàng',
      store: 'Cửa hàng',
      select_brand: 'Chọn thương hiệu',
      select_store: 'Chọn cửa hàng',
      select_category: 'Chọn danh mục',
      select_seller: 'Chọn người bán',
      select_subcategory: 'Chọn danh mục con',
      no_product: 'Chưa có sản phẩm...',
      no_category: 'Chưa có danh mục..',
      no_information: 'Chưa có thông tin..',
      no_sub_category: 'Chưa có danh mục con..',
      no_promotion: 'Chưa có khuyến mãi...',
      variants: 'Lựa chọn',
      variant: 'Lựa chọn',
      sku: 'Mã sản phẩm',
      sold: 'Đã bán',
      ratings: 'Đánh giá',
      similar_products: 'Sản phẩm tương tự',
      product_reviews: 'Đánh giá sản phẩm',
      product_status: 'Trạng thái sản phẩm',
      product_name: 'Tên sản phẩm',
      product_slug: 'Slug',
      brand_desc:
        'Chọn GENERIC nếu sản phẩm không có thương hiệu. Vui lòng liên hệ với chúng tôi nếu bạn muốn thêm thương hiệu.',
      product_descriptions: 'Mô tả sản phẩm',
      product_variants: 'Lựa chọn sản phẩm',
      add_variant: 'Thêm lựa chọn khác',
      variant_name: 'Tên lựa chọn',
      availability: 'Hiển thị',
      availability_desc:
        'Để ẩn lựa chọn này trên cửa hàng của bạn, bỏ chọn "Hiển thị trên cửa hàng".',
      visible_marketplace: 'Hiển thị trên mục Mua sắm',
      allow_purchase: 'Cho phép mua',
      available_to_buy: 'Hiển thị trên cửa hàng',
      price: 'Giá bán',
      original_price: 'Giá gốc',
      original_price_desc: `Để hiển thị giá giảm, điền giá gốc của sản phẩm vào mục "Giá gốc". Điền giá đã giảm vào mục "Giá bán".`,
      variant_sku: 'Mã sản phẩm (SKU)',
      variant_barcode: 'Mã vạch (UPC, GTIN, ISBN, v.v.)',
      barcode: 'Mã vạch',
      inventory_count: 'Số lượng hàng trong kho',
      inventory_low_level: 'Mức hàng tối thiểu',
      inventory_low_level_desc: `Để hiển thị thông báo "Hãy nhanh tay! Chỉ còn lại X món", điền vào mục "Số lượng hàng trong kho", và số lượng thấp hơn vào mục "Mức hàng tối thiểu".`,
      weight: 'Cân nặng',
      weight_desc: 'Dùng để tính phí vận chuyển.',
      dimensions: 'Kích thước',
      dimensions_lwh: 'Kích thước (Dài, Rộng, Cao)',
      material: 'Chất liệu',
      size: 'Kích cỡ',
      delete_product: 'Xóa sản phẩm',
      banner: 'Banner',
      quantity: 'Số lượng',
      inventory_adjustment: 'Điều chỉnh hàng tồn kho',
      warehouse: 'Kho hàng',
      shipping: 'Vận chuyển',
      stock_item_type_add: 'Thêm',
      stock_item_type_deduct: 'Bớt',
      stock_item_type_update: 'Cập nhật',
      reason: 'Lý do',
      stock_item_activity_initial_inventory: 'Hàng tồn kho ban đầu',
      stock_item_activity_count: 'Đếm hàng',
      stock_item_activity_correction: 'Điều chỉnh',
      stock_item_activity_transfer: 'Chuyển kho',
      stock_item_activity_damage: 'Hư hỏng',
      stock_item_activity_returns: 'Trả hàng',
      stock_item_activity_other: 'Khác',
      order_created: 'Đã tạo đơn hàng',
      order_canceled: 'Đơn hàng đã hủy',
      date: 'Ngày',
      activity: 'Hoạt động',
      stock: 'Số hàng tồn',
      variant_description: 'Mô tả lựa chọn',
      variant_slug: 'Slug lựa chọn',
      pricing: 'Giá cả',
      arrange_variants: 'Sắp xếp lựa chọn',
      export_import: 'Xuất & Nhập dữ liệu',
      export: 'Xuất dữ liệu',
      import: 'Nhập dữ liệu',
      export_product_desc:
        'Sử dụng mẫu có sẵn để cập nhật hàng loạt thông tin sản phẩm một cách hiệu quả.',
      import_product_desc_1:
        'Kích thước tối đa: 5 MB ở định dạng XLSX, XLS, hoặc CSV.',
      import_product_desc_2:
        'Vui lòng tải lên mẫu mới nhất để tránh lỗi khi nhập dữ liệu.',
      import_product_desc_3:
        'Vui lòng không cập nhật thông tin sản phẩm khi quá trình nhập dữ liệu chưa hoàn tất.',
      template: 'Mẫu',
      generate_export: 'Tạo mẫu xuất dữ liệu',
      start_import_flow: 'Bắt đầu nhập dữ liệu',
      import_pricing: 'Giá cả',
      import_inventory: 'Số lượng kho',
      pricing_desc: `Để cập nhật thông tin giá sản phẩm: "Giá bán" và "Giá gốc"`,
      inventory_desc: `Để cập nhật thông tin hàng tồn kho: "Số lượng hàng trong kho"`,
      product_id: 'Mã sản phẩm',
      variant_id: 'Mã loại',
      correction: 'Sửa chữa',
      count: 'Sai số lượng',
      transfer: 'Vận chuyển',
      damage: 'Hư hại',
      returns: 'Hoàn trả',
      other: 'Khác',
      purchase_limit_per_type_day: 'mỗi ngày',
      purchase_limit_per_type_week: 'mỗi tuần',
      purchase_limit_per_type_month: 'mỗi tháng',
      purchase_limit: 'Giới hạn mua hàng',
      purchase_limit_desc:
        'Giới hạn số lượng mỗi khách hàng có thể mua trong một khoảng thời gian nhất định.',
    },
    promotions: {
      type: 'Loại',
      promotion_name: 'Tên chương trình',
      status: 'Trạng thái',
      status_type: 'Phân loại',
      apply_time: 'Thời gian áp dụng',
      promotion_sku: 'Số món',
      banner: 'Banner',
      add_banner: 'Tạo banner',
      add_sale: 'Tạo giảm giá',
      discount: 'Giảm giá siêu tốc',
      combo: 'Combo khuyến mãi',
      coupon_code: 'Mã giảm giá',
      item_discount: 'Giảm giá sản phẩm',
      shipping_discount: 'Giảm giá vận chuyển',
      bonus_gift: 'Quà tặng kèm',
      title: 'Tiêu đề',
      sub_title: 'Tiêu đề phụ',
      description: 'Mô tả',
      banner_desc:
        'Tạo quảng cáo bắt mắt bằng banner giúp gia tăng sự chú ý cho người mua.',
      discount_desc:
        'Tăng doanh số thông qua chương trình khuyến mãi giảm giá siêu tốc.',
      combo_desc:
        'Tạo gói sản phẩm bao gồm nhiều sản phẩm khác nhau được bán với giá ưu đãi.',
      coupon_code_desc:
        'Mã giảm giá giúp tăng doanh số bán hàng và thu hút khách hàng mới.',
      item_discount_desc:
        'Thu hút sự chú ý của người mua bằng cách giảm giá sản phẩm.',
      shipping_discount_desc:
        'Giảm bớt chi phí cho khách hàng, từ đó khuyến khích họ mua sắm nhiều hơn.',
      bonus_gift_desc:
        'Tặng quà kèm theo sản phẩm giúp tăng giá trị sản phẩm và thu hút khách hàng.',
      find_by_name: 'Tìm theo tên hoặc mã',
      find_by_cate: 'Tìm theo danh mục',
      find_by_brand: 'Tìm theo thương hiệu',
      find_by_seller: 'Tìm theo người bán',
      product_list: 'Danh sách sản phẩm',
      selected_products: 'Món đã chọn',
      original_price: 'Giá gốc',
      discount_price: 'Giá giảm',
      available_stock: 'Tồn kho',
      total_item: 'Số lượng',
      item_per_order: 'Giới hạn',
      delete_item: 'Xóa',
      create_promotion: 'Tạo khuyến mãi mới',
      promotion_slug: 'Slug',
      save_info: 'Lưu thông tin',
      save_and_select_product: 'Lưu và nhập sản phẩm',
      start_date: 'Bắt đầu từ',
      end_date: 'Kết thúc vào',
      all_selected_promotions: 'Tất cả sản phẩm đã chọn',
      remove_items: 'Xóa {{items}} sản phẩm',
      existing_variants: 'Sản phẩm đã có ở khuyến mãi khác',
      discount_amount: 'Số tiền giảm',
      selling_price: 'Giá đang bán',
      select_products: 'Chọn sản phẩm',
    },
    form: {
      common: {
        field_logo: 'Logo {{field}}',
        field_name: 'Tên {{field}}',
        field_status: 'Trạng thái {{field}}',
        field_code: 'Mã {{field}}',
        drop_file_here: 'Thả tập tin của bạn ở đây',
        type: 'Loại',
      },
      invalid: {
        required: '{{fieldName}} là bắt buộc!',
        invalid: '{{fieldName}} không hợp lệ!',
        load_fail: 'Tải {{field}} thất bại!',
        greater_than_equal_0: 'phải lớn hơn hoặc bằng 0',
        greater_than_equal: 'phải lớn hơn hoặc bằng',
      },
      information: {
        information: 'Thông tin',
        title: 'Tiêu đề',
        subtitle: 'Tiêu đề phụ',
        content: 'Nội dung',
        type: 'Vị trí đặt',
        english: 'Tiếng Anh',
        vietnamese: 'Tiếng Việt',
        type_guide: 'Hướng dẫn chọn vị trí đặt',
        type_guide_pages: 'Trang thông tin',
        type_guide_home_header: 'Ở trên đầu mỗi trang',
        type_guide_home_body: 'Ở phần thân trang chủ',
        type_guide_product_details: 'Trong trang chi tiết sản phẩm',
        type_guide_shopping_cart: 'Mục giỏ hàng',
        type_guide_detail_pages:
          'Thông tin sẽ hiện ở header (phần trên cùng của website).',
        type_guide_detail_home_header:
          'Thông tin sẽ hiện ở header (phần trên cùng của website).',
        type_guide_detail_home_body: 'Thông tin sẽ hiện ở giữa trang chính.',
        type_guide_detail_product_details:
          'Thông tin sẽ hiện ở trang sản phẩm.',
        type_guide_detail_shopping_cart:
          'Thông tin sẽ hiện ở trang giỏ hàng của người dùng.',
        articles: {
          shopping_with_nailzy: 'Mua hàng cùng Nailzy',
          general_information: 'Thông tin chung',
          payments: 'Thanh toán',
          orders_shipping: 'Đơn hàng & Vận chuyển',
          returns_refunds: 'Trả hàng & Hoàn tiền',
          offers_promotions: 'Ưu đãi & Khuyến mại',
        },
        section: 'Chuyên mục',
      },
      change_mail: {
        input_mail_to_confirm: 'Input your password to confirm',
        input_email: 'Nhập email',
        input_email_to_change: 'Nhập email bạn muốn thay đổi',
      },
      change_phone: {
        otp_form_resent_code_after: 'Gửi lại mã sau',
        add_phone_success: 'Thêm số điện thoại thành công',
        add_phone_fail: 'Thêm số điện thoại không thành công',
        update_phone_success: 'Cập nhật số điện thoại không thành công',
        update_phone_fail: 'Cập nhật số điện thoại không thành công',
        send_otp_error: 'Gửi mã xác minh bị lỗi',
        input_your_phone_number: 'Nhập số điện thoại',
        phone_number_you_want_to_change: 'Nhập số điện thoại bạn muốn thay đổi',
        verify_OTP_code: 'Xác thực mã OTP',
        code_has_been_sent: 'Mã xác minh đã được gửi đến số điện thoại',
        verify_successful: 'Xác thực thành công',
        phone_number_has_been_changed: 'Số điện thoại đã được thay đổi',
      },
      store: {
        store_details: 'Thông tin cửa hàng',
        business_type: 'Loại hình kinh doanh',
        business_type_company: 'Công ty',
        business_type_individual: 'Cá nhân',
        legal_business_name: 'Tên doanh nghiêp kinh doanh hợp pháp',
        tax_id: 'Mã thuế (EIN, ITIN, hoặc SSN)',
        upload_document: 'Tải lên tài liệu',
        image_limit_error: 'Number of images is limit to 6',
        upload_images_condition_1: 'Kích thước: 2000 px ngang x 769 px dọc',
        upload_images_condition_2: 'Tối đa: 6 hình, dung lượng 2.0 MB mỗi hình',
        upload_images_condition_3: 'Định dạng hỗ trợ: JPG, PNG, WEPB',
        document: 'Tài liệu',
        store_status: 'Trạng thái cửa hàng',
        store_status_desc:
          'Bạn có thể cung cấp tài liệu để xác minh cửa hàng ở đây.',
        documents: 'Tài liệu',
        banner_desc: 'Banner sẽ được hiển thị ở trang cửa hàng của bạn.',
        fbn: 'Lưu kho Nailzy (FBN)',
        seller_stocks_and_fulfill: 'Lưu kho người bán',
        fulfillment_type: 'Loại lưu kho',
      },
      account: {
        update_profile_error: 'Lỗi cập nhật hồ sơ',
        profile_picture: 'Ảnh đại diện',
        security_subtitle:
          'Bạn có thể thêm hoặc cập nhật phương thức đăng nhập của mình.',
        social_linking_title: 'Liên kết mạng xã hội',
        social_linking_subtitle:
          'Đăng nhập nhanh hơn bằng cách liên kết phương tiện truyền thông xã hội với tài khoản của bạn.',
      },
      coupon: {
        coupon: 'Mã giảm giá',
        coupon_code: 'Mã giảm giá',
        percentage: 'Phần trăm',
        fixed_amount: 'Giá cố định',
        discount_value: 'Giá trị giảm',
        quantity: 'Số lượng',
        selection: 'Lựa chọn mục tiêu',
        apply_to: 'Áp dụng cho',
        all_products: 'Tất cả sản phẩm',
        specific_cates: 'Danh mục cụ thể',
        specific_sellers: 'Người bán cụ thể',
        specific_brands: 'Thương hiệu cụ thể',
        requirements: 'Yêu cầu',
        minimum_purchase_requirements: 'Yêu cầu mua tối thiểu',
        minimum_purchase_amount: 'Theo số tiền mua tối thiểu',
        purchase_quantity: 'Theo số lượng mua tối thiểu',
        target_customer: 'Khách hàng mục tiêu',
        customer_eligibility: 'Điều kiện của khách hàng',
        all_customers: 'Tất cả khách hàng',
        specific_customers: 'Khách hàng cụ thể',
        limit: 'Giới hạn',
        usage_limit: 'Giới hạn sử dụng',
        one_use_per_customer: 'Một lần sử dụng cho mỗi khách hàng',
        multiple_uses_per_customer: 'Nhiều lần sử dụng cho mỗi khách hàng',
        numbers_of_uses: 'Số lần sử dụng',
        valid_from: 'Bắt đầu từ',
        valid_until: 'Kết thúc vào',
        excludes: 'Loại trừ',
        excludes_by: 'Loại trừ theo',
        no_exception: 'Không có loại trừ',
      },
    },
    filter: {
      filters: 'Bộ lọc',
      joined_date_range: 'Phạm vi ngày đã tham gia',
      role: 'Vai trò',
    },
    reviews: {
      review: 'Nội dung',
      rating: 'Đánh giá',
      tags: 'Thẻ',
      reviews_count: 'lượt đánh giá',
      dissatisfied: 'Chưa hài lòng',
      acceptable: 'Tạm ổn',
      good: 'Khá tốt',
      satisfied: 'Hài lòng',
      very_satisfied: 'Rất hài lòng',
      product_quality: 'Chất lượng sản phẩm',
      delivery_packaging: 'Chất lượng sản phẩm',
      reasonable_price: 'Giá cả sản phẩm',
      enthusiastic_seller: 'Người bán hàng',
      other_reason: 'Khác',
      add_review_success: 'Đánh giá thành công',
      edit_review: 'Sửa đánh giá',
    },
    customer: {
      role_type: 'Loại khách hàng',
      auth_provider: 'Hình thức đăng nhập',
      business_phone: 'Số điện thoại tiệm',
      business_name: 'Tên tiệm',
      amount_spent: 'Số tiền đã chi',
      nail_supply: 'Chủ tiệm supply',
      nail_tech: 'Thợ nail',
      salon_owner: 'Chủ tiệm nail',
      other: 'Khác',
      create_customer: 'Tạo khách hàng',
      edit_customer: 'Sửa khách hàng',
      delete_customer: 'Xóa khách hàng',
      delete_customer_note:
        'Lưu ý: Xóa khách hàng này sẽ xóa vĩnh viễn những thông tin và dữ liệu liên quan đến tài khoản của khách.',
      delete_customer_reason: 'Lý do xóa',
      send_email_reset_pass: 'Gửi email khôi phục mật khẩu',
      send_email_reset_pass_success: 'Gửi email thành công!',
      send_email_reset_pass_fail: 'Gửi email thất bại!',
      export_customer: 'Xuất danh sách khách hàng',
      please_select_add_customer:
        'Vui lòng chọn khách hàng hoặc thêm địa chỉ mới',
      referral_code: 'Mã giới thiệu',
      referrals: 'Giới thiệu',
      referred_by: 'Giới thiệu bởi',
    },
    sellers: {
      add_seller: 'Thêm người bán',
      seller: 'Người bán',
      seller_selected: 'người bán đã chọn',
      no_seller_found: 'Không tìm thấy người bán!',
      edit_store: 'Sửa thông tin cửa hàng',
      edit_seller: 'Sửa hồ sơ người bán',
      edit_bank: 'Sửa thông tin ngân hàng',
      edit_warehouse: 'Sửa kho hàng',
      create_warehouse: 'Tạo kho hàng',
      delete_seller: 'Xóa cửa hàng',
      seller_profile: 'Hồ sơ người bán',
      total_orders: 'Tổng đơn hàng',
      total_sales: 'Doanh thu dự kiến',
      completed_orders: 'Đơn hàng hoàn thành',
      completed_sales: 'Doanh thu thực tế',
      total_orders_decs: 'Tổng số lượng đơn hàng đã đặt',
      total_sales_decs: 'Tổng giá trị đơn hàng đã đặt',
      completed_orders_decs: 'Tổng đơn hàng đã hoàn thành',
      completed_sales_decs:
        'Tổng doanh thu đã ghi nhận theo đơn hàng hoàn thành',
      store_address: 'Địa chỉ cửa hàng',
      warehouse: 'Kho hàng',
    },
    skills: {
      skill: 'Kỹ năng',
      skill_name: 'Tên kỹ năng',
      no_skill_found: 'Không tìm thấy kỹ năng!',
      skill_selected: 'kỹ năng được lựa chọn',
      add_skill: 'Thêm kỹ năng',
    },
    warehouses: {
      page_sub_title:
        'Quản lý nơi bạn dự trữ hàng tồn kho, xử lý đơn hàng, và bán sản phẩm.',
      warehouse_name: 'Tên kho',
      warehouse_address: 'Địa chỉ',
      warehouse_status: 'Tình trạng kho',
      product_variant: 'Tổng số lựa chọn sản phẩm',
      inventory_quantity: 'Số lượng hàng tồn kho',
    },
    packages: {
      package: 'Gói hàng',
      packages: 'Gói hàng',
      packages_decs: 'Mẫu thùng định sẵn dùng để lúc mua nhãn vận chuyển.',
      edit_package: 'Sửa gói hàng',
      add_package: 'Thêm gói hàng',
      package_name: 'Tên gói hàng',
      default_package: 'Gói hàng mặc định',
      default_package_label: 'Dùng để tính phí vận chuyển khi thanh toán.',
      name: 'Tên',
      length: 'Dài',
      width: 'Rộng',
      height: 'Cao',
      weight: 'Cân nặng',
    },
    draft_carts: {
      draft_carts: 'Giỏ hàng nháp',
      draft_cart: 'Giỏ hàng nháp',
      save_draft: 'Lưu nháp',
      add_draft_carts: 'Thêm giỏ hàng nháp',
    },
    shipping: {
      shipping_rates: 'Mức phí vận chuyển',
      shipping_desc:
        'Thiết lập phí vận chuyển lúc thanh toán và quản lý vùng vận chuyển.',
      live_rate: 'Mức tự động',
      live_rate_desc:
        'Phí vận chuyển lúc thanh toán sẽ được tự động tính bởi dịch vụ của bên thứ ba.',
      flat_rate: 'Mức cố định',
      flat_rate_desc: 'Tính một mức giá vận chuyển cố định khi thanh toán.',
      shipping_zones: 'Vùng vận chuyển',
      shipping_zones_desc: 'Nơi đơn hàng có thể được giao đến.',
      carrier_name: 'Tên đơn vị vận chuyển',
      amount_estimated: 'Mức giá & ngày nhận hàng dự kiến',
      calculated: 'Sẽ được tính',
      rate_name: 'Tên mức',
      estimated_days: 'Ngày nhận hàng dự kiến',
      conditions: 'Điều kiện',
      amount: 'Mức giá',
      add_flat_rate: 'Thêm mức cố định',
      update_flat_rate: 'Cập nhật mức cố định',
      base_on_price: 'Tính theo giá trị đơn hàng',
      base_on_weight: 'Tính theo trọng lượng đơn hàng',
      min_price: 'Giá tối thiểu',
      max_price: 'Giá tối đa',
      min_weight: 'Trọng lượng tối thiểu',
      max_weight: 'Trọng lượng tối đa',
      condition_pricing: 'Gái theo điều kiện',
      manage_shipping_zones: 'Quản lý vùng vận chuyển',
      countries_and_states: 'Quốc gia & tiểu bang/tỉnh',
      states: 'Tiểu bang',
      update_shipping_rate: 'Đổi phí vận chuyển',
      delete_shipping_rate: 'Xóa phí vận chuyển',
      confirm_update_shipping_rate: 'Bạn muốn đổi mức phí vận chuyển sang ',
      min_price_must_grater_than_0: 'Giá tối thiểu phải lớn hơn 0',
      max_price_must_grater_than_min:
        'Giá tối đa không được nhỏ hơn giá tối thiểu',
      min_weight_must_grater_than_0: 'Cân nặng tối thiểu phải lớn hơn 0',
      max_weight_must_grater_than_min:
        'Cân nặng tối đa không được nhỏ hơn cân nặng tối thiểu',
    },
    system_users: {
      system_users: 'Người dùng hệ thống',
      system_users_decs: 'Quản lí vai trò và phân quyền cho người dùng nội bộ.',
      roles: 'Vai trò',
      roles_decs:
        'Quản lý phân quyền người dùng hệ thông bằng cách cho phép hoặc từ chối truy cập các chức năng cụ thể.',
      add_role: 'Thêm vai trò',
      add_system_user: 'Thêm người dùng',
      edit_system_user: 'Sửa người dùng',
      edit_role: 'Sửa vai trò',
      permissions: 'Phân quyền',
      role_name: 'Tên vài trò',
      is_super_admin: 'Quản lí cấp cao?',
      action_get: 'XEM',
      action_post: 'TẠO',
      action_put: 'SỬA',
      action_delete: 'XÓA',
      role_and_permission: 'Vai trò & phân quyền',
    },
  },
};

export default vi;
