import { ReactNode } from 'react';
type PropType = {
  children?: ReactNode;
  className?: string;
};
const SectionBody = (props: PropType) => {
  // ### CONSTANTs
  const { children, className } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return <div className={className}>{children}</div>;
};
export default SectionBody;
