import { ActionIcon, Anchor, Flex, Grid, Text, Title } from '@mantine/core';
import { RoleItem, StatusEnum } from 'types';
import IconFA from 'components/common/IconFA';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import CommonAvatar from 'components/MantineUI/CommonAvatar';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import StatusBadge from 'components/MantineUI/StatusBadge';
import UploadWrapper from 'components/MantineUI/UploadWrapper';
import {
  dateTimeFormatter,
  phoneFormatter,
  relativeTimestamp,
} from 'helpers/utils';
import { t } from 'i18next';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { faMap } from '@fortawesome/pro-light-svg-icons';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import { useDeleteSystemUser, useSystemUserDetails } from 'apis/UserApis';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
import UserDetailsModal from './UserDetailsModal';
import RoleDetailsModal from '../roles/components/RoleDetailsModal';

const SystemUserDetails = () => {
  // ### CONSTANTs

  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading }: any = useSystemUserDetails(id);
  const user = data?.data;
  const updatedOn = dateTimeFormatter(user?.updated_on);
  const [selectedRole, setSelectedRole] = useState<RoleItem>();
  const [deleteUser, setDeleteUser] = useState<any>();
  const { mutate: deleteUserAPI, isPending: deleting } = useDeleteSystemUser();
  const [selected, setSelected] = useState<any>();

  // ### FUNCTIONs

  const onDeleteUser = () => {
    deleteUserAPI(deleteUser?._id, {
      onSuccess: () => {
        setDeleteUser(undefined);
        navigate('/system-users');
      },
    });
  };
  // ### RENDERs
  const name = user?.full_name || '--';
  return (
    <SkeletonWrapper show={isLoading} skeleton={<SectionPageSkeleton />}>
      <PageLayout
        title={t('system_users.system_users')}
        breadCrumds={[
          { title: t('system_users.system_users'), href: '/system-users' },
          { title: name || id },
        ]}
      >
        <Grid>
          <Grid.Col span={{ base: 12, sm: 8 }}>
            <SectionWrapper>
              <Flex>
                <Flex flex={1} align={'center'}>
                  <UploadWrapper
                    resize={{ width: 1200, height: 1200 }}
                    className="w-fit cursor-pointer"
                    onFileChange={(files: any) => {}}
                  >
                    <CommonAvatar
                      size={'xl'}
                      radius={90}
                      user={user}
                      src={user?.logo?.src ?? user?.logo?.url ?? user?.logo}
                    />
                  </UploadWrapper>
                  <Flex direction={'column'} flex={1} px={'md'}>
                    <Title order={1}>{name}</Title>
                    <Text size="sm">
                      {t('general.joined_on')}{' '}
                      {relativeTimestamp(user?.created_on)}{' '}
                      {updatedOn !== 0 && (
                        <>
                          {' '}
                          &#x2022; {t('general.last_update_on')} {updatedOn}{' '}
                        </>
                      )}
                    </Text>
                    <StatusBadge
                      className="mt-1"
                      status={user?.status || StatusEnum.ACTIVE}
                    />
                  </Flex>
                </Flex>
                <DropdownButton
                  dropdownData={[
                    {
                      label: t('system_users.edit_system_user'),
                      key: 'edit',
                      onClick: () => setSelected(user),
                    },
                    // {
                    //   label: t('customer.send_email_reset_pass'),
                    //   key: 'reset',
                    // },
                    { isDivider: true },
                    {
                      label: t('general.delete'),
                      key: 'delete',
                      color: 'red',
                      onClick: () => setDeleteUser(user),
                    },
                  ]}
                ></DropdownButton>
              </Flex>
            </SectionWrapper>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 4 }}>
            <SectionWrapper>
              <SectionHeader
                isSubSection
                title={t('form.information.information')}
              />
              <SectionBody>
                <Flex align={'end'} mb={'xs'}>
                  <div className="flex-1">
                    <Text size="sm" c={'neutral.6'}>
                      {t('filter.role')}
                    </Text>

                    <Anchor
                      onClick={() => setSelectedRole(user?.permission)}
                      truncate="end"
                    >
                      {user?.permission?.name || '--'}
                    </Anchor>
                  </div>
                </Flex>
                <Flex align={'end'} mb={'xs'}>
                  <div className="flex-1">
                    <Text size="sm" c={'neutral.6'}>
                      {t('register.form.email')}
                    </Text>
                    <Text flex={1} truncate="end">
                      {user?.email || '--'}
                    </Text>
                  </div>
                  {user?.email && <CopyButton value={user?.email} />}
                </Flex>
                <Flex align={'end'} mb={'xs'}>
                  <div className="flex-1">
                    <Text size="sm" c={'neutral.6'}>
                      {t('general.phone')}
                    </Text>
                    <Text flex={1} truncate="end">
                      {user?.phone_number
                        ? phoneFormatter(user?.phone_number)
                        : '--'}
                    </Text>
                  </div>
                  {user?.phone_number && (
                    <CopyButton value={user?.phone_number} />
                  )}
                </Flex>

                <Flex align={'end'} mb={'xs'}>
                  <div className="flex-1">
                    <Text size="sm" c={'neutral.6'}>
                      {t('register.form.address')}
                    </Text>
                    <Text flex={1}>{user?.address?.location || '--'}</Text>
                  </div>
                  {user?.address?.location && (
                    <ActionIcon
                      color={'gray'}
                      variant="subtle"
                      onClick={() =>
                        window.open(
                          `https://maps.google.com?q=${user?.address?.location}`,
                          '_blank',
                        )
                      }
                    >
                      <IconFA icon={faMap} type="light" />
                    </ActionIcon>
                  )}
                  {user?.address?.location && (
                    <CopyButton value={user?.address?.location} />
                  )}
                </Flex>
              </SectionBody>
            </SectionWrapper>
          </Grid.Col>
        </Grid>
        <UserDetailsModal
          data={selected}
          onClose={() => setSelected(undefined)}
          opened={!!selected}
        />
        <RoleDetailsModal
          opened={!!selectedRole}
          onClose={() => setSelectedRole(undefined)}
          data={selectedRole}
        />
        <DeleteWarningModal
          onShow={!!deleteUser}
          name={deleteUser?.full_name}
          onClose={() => setDeleteUser(undefined)}
          onDelete={onDeleteUser}
          isLoading={deleting}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default SystemUserDetails;
