import { faSortAmountDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, keys, useMantineTheme } from '@mantine/core';
import { useURLParams } from 'hooks/useURLParams';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import IconFA from '../IconFA';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';

const SortDropdownButton = ({ sortArray, isDisabled }) => {
  const { t } = useTranslation();

  const { params, handleParamChange } = useURLParams();
  const dropdownData = useMemo(() => {
    return sortArray.map((route, i) => ({
      onClick: () => {
        const sortValue = params.sort === route?.path ? null : route?.path;
        handleParamChange('sort')(sortValue);
      },
      label: route?.name,
      key: route?.path,
    }));
  }, [sortArray]);

  return (
    <DropdownButton
      disabled={isDisabled}
      selected={params?.sort}
      button={
        <Button
          size="xs"
          variant="default"
          leftSection={<IconFA icon="arrow-down-short-wide" />}
          rightSection={<IconFA icon="caret-down" />}
        >
          <span className="d-none d-sm-inline-block">{t('general.sort')}</span>
        </Button>
      }
      dropdownData={dropdownData}
    />
  );
};

export default SortDropdownButton;
