import { Autocomplete } from '@mantine/core';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import React, { ReactNode, useCallback, useEffect, useState } from 'react';
import * as lodash from 'lodash';
import { AddressInfo } from 'types';
import { formatAddress } from 'helpers/utils';
import AutoCompleteInput from './AutoCompleteInput';
type PropType = {
  children?: ReactNode;
  className?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  address?: AddressInfo;
  onChange?: (addr: AddressInfo) => void;
};
export const getAddressDetail = (addressComponents: any[] = []) => {
  const address: any = {};
  for (let i = 0; i < addressComponents.length; i++) {
    const types = addressComponents[i].types;
    const long_name = addressComponents[i].long_name;
    const short_name = addressComponents[i].short_name;
    for (let j = 0; j <= types.length; j++) {
      switch (types[j]) {
        case 'postal_code':
          address.postal_code = { short_name, long_name };
          break;
        case 'country':
          address.country = { short_name, long_name };
          break;
        case 'administrative_area_level_1':
          address.administrative_area_level_1 = { short_name, long_name };
          break;
        case 'administrative_area_level_2':
          address.administrative_area_level_2 = { short_name, long_name };
          break;
        case 'locality':
          address.locality = { short_name, long_name };
          break;
        case 'neighborhood':
          address.neighborhood = { short_name, long_name };
          break;
        case 'route':
          address.route = { short_name, long_name };
          break;
        case 'sublocality_level_1':
          address.sublocality_level_1 = { short_name, long_name };
          break;
        case 'sublocality_level_2':
          address.sublocality_level_2 = { short_name, long_name };
          break;
        case 'premise':
          address.premise = { short_name, long_name };
          break;
        case 'subpremise':
          address.subpremise = { short_name, long_name };
          break;
        case 'street_number':
          address.street_number = { short_name, long_name };
          break;
        default:
          break;
      }
    }
  }

  return address;
};
const AddressInput = (props: PropType) => {
  // ### CONSTANTs
  const libraries = ['places'] as any;
  const {
    label = '',
    className = '',
    onChange,
    address,
    disabled = false,
    required = false,
  } = props;
  const [loading, setLoading] = useState<any>();
  const [search, setSearch] = useState('');
  const [options, setOptions] = useState([]);
  const [placeService, setPlaceService] = useState<any>();
  const [findPlaceFromQuery, setFindPlaceFromQuery] = useState<any>();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY as string,
    libraries,
  });

  const onMapLoad = (map: any) => {
    const service = new window.google.maps.places.AutocompleteService();
    const findPlaceFromQuery = new window.google.maps.places.PlacesService(map);

    setPlaceService(() => service);
    setFindPlaceFromQuery(() => findPlaceFromQuery);
  };
  // ### FUNCTIONs

  const searchPlaces = async (query: string) => {
    setLoading(true);
    try {
      const results = await placeService?.getPlacePredictions({
        input: query,
      });

      return results.predictions || [];
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setLoading(false);
    }
  };

  const onSearch = async (text: string) => {
    const places = await searchPlaces(text);

    const data = places.map((item: any) => ({
      value: item.place_id,
      label: item.description,
    }));
    setOptions(data);
  };
  const debounceSearch = useCallback(
    lodash.debounce((search) => onSearch(search), 500),
    [placeService],
  );
  useEffect(() => {
    if (search) {
      debounceSearch(search);
    } else {
      setOptions([]);
    }
  }, [search]);

  const handleLocationSelected = (value: string) => {
    if (!value) {
      return;
    }
    findPlaceFromQuery.getDetails(
      {
        placeId: value,
        fields: ['formatted_address', 'address_components', 'geometry'],
      },
      (result: any) => {
        const addressDetail = getAddressDetail(result?.address_components);
        const formattedAddress = formatAddress({
          ...addressDetail,
          location: result?.formatted_address,
          lat: result?.geometry?.location?.lat?.()?.toString(),
          lon: result?.geometry?.location?.lng?.()?.toString(),
        }) as AddressInfo;
        onChange?.(formattedAddress);
      },
    );
  };

  // ### RENDERs
  return (
    <div className={className} key={address?._id}>
      {isLoaded ? <GoogleMap onLoad={onMapLoad} /> : null}
      <AutoCompleteInput
        required={required}
        disabled={disabled}
        label={label}
        data={options}
        onInput={(text) => {
          setSearch(text || '');
        }}
        loading={loading}
        onSelect={(data) => handleLocationSelected(data?.value)}
        placeholder={'123 Main St, Dallas, TX 75123'}
        defaultValue={address?.location}
      />
    </div>
  );
};
export default AddressInput;
