import { createTheme, rem } from '@mantine/core';

const theme = createTheme({
  primaryColor: 'primary',
  // 'dark' | 'gray' | 'red' | 'pink' | 'grape' | 'violet' | 'indigo'
  //  'blue' | 'cyan' | 'green' | 'lime' | 'yellow' | 'orange' | 'teal'
  colors: {
    primary: [
      '#FFF0F9',
      '#FFE6EF',
      '#FFA3C8',
      '#FF7AB4',
      '#FF52A3',
      '#FF2994',
      '#FF0088',
      '#D9007B',
      '#B3006B',
      '#8C0059',
      '#660044',
    ],
    neutral: [
      '#FFFFFF',
      '#FAFAFA',
      '#F5F5F5',
      '#F0F0F0',
      '#D9D9D9',
      '#BFBFBF',
      '#8C8C8C',
      '#595959',
      '#434343',
      '#262626',
      '#1F1F1F',
      '#141414',
      '#111111',
    ],
    'day-break': [
      '#E6F7FF',
      '#BAE7FF',
      '#91D5FF',
      '#69C0FF',
      '#40A9FF',
      '#1890FF',
      '#096DD9',
      '#0050B3',
      '#003A8C',
      '#002766',
    ],
    dust: [
      '#FFF1F0',
      '#FFCCC7',
      '#FFA39E',
      '#FF7875',
      '#FF4D4F',
      '#F5222D',
      '#CF1322',
      '#A8071A',
      '#820014',
      '#5C0011',
    ],
    polar: [
      '#F6FFED',
      '#D9F7BE',
      '#B7EB8F',
      '#95DE64',
      '#73D13D',
      '#52C41A',
      '#389E0D',
      '#237804',
      '#135200',
      '#092B00',
    ],
    calendula: [
      '#FFFBE6',
      '#FFEEB8',
      '#FFE58F',
      '#FFD666',
      '#FFC53D',
      '#FAAD14',
      '#D48806',
      '#AD6800',
      '#874D00',
      '#613400',
    ],
  },
});

export default theme;
