import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useURLParams } from 'hooks/useURLParams';
import { FormControl, InputGroup } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons';
import { Input } from '@mantine/core';

const TableSearchBox = ({
  size = 'xs',
  placeholder = '',
  className = '',
  searchParams = 'search'
}) => {
  const { t } = useTranslation();

  const { params, handleParamChange } = useURLParams();
  const [value, setValue] = useState(params?.[searchParams]);

  const handleDebounceChange = useAsyncDebounce(value => {
    handleParamChange(searchParams)(value || undefined);
  }, 500);

  const handleSearchChange = event => {
    const value = event.target.value;
    setValue(value);
    handleDebounceChange(value);
  };

  return (
    <Input
      value={value || ''}
      size={size}
      placeholder={placeholder || t('general.search')}
      onChange={handleSearchChange}
      leftSection={
        <FontAwesomeIcon icon={faMagnifyingGlass} className="fs--1" />
      }
    />
  );
};

export default TableSearchBox;
