import { useMutation, useQuery } from '@tanstack/react-query';
import { dataFetchingQueryConfig } from 'config';
import dayjs from 'dayjs';
import { sellerKeys } from 'query-keys/seller-keys';
import queryString from 'query-string';
import { apiCall, apiCallFile, apiCallV2 } from './ApiCall';
import { APP_ROLES } from 'constants/constants';
import { useAuth } from 'contexts/AuthContext';

const config = dataFetchingQueryConfig;

const now = dayjs().unix(); // Get today with unix timestamp
const sevenDaysAgo = dayjs().subtract(7, 'day').unix(); // Get 7 days ago with unix timestamp

// --------------- NOTE: used when logged in as Seller

const API_END_POINT = 'admin/sellers';

export const useGetSellers = (params = {}, enabled = true) => {
  const controller = new AbortController();
  const url = `${API_END_POINT}?${queryString.stringify(params)}`;

  return useQuery({
    queryKey: sellerKeys.list(params),
    queryFn: () => apiCallV2({ url, signal: controller.signal }),
    ...config,
    enabled,
  });
};

export const useGetSellerDetails = (data, key = []) => {
  const controller = new AbortController();
  const url = `${API_END_POINT}/${data.admin_id}`;
  return useQuery({
    queryKey: ['useGetSellerDetails', data.admin_id],
    queryFn: () => apiCallV2({ url, signal: controller.signal }),
    // ...config
  });
};

export const useUpdateSellerProfile = () => {
  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const controller = new AbortController();
      const url = `admin/profile`;
      delete data?.address?._id;
      delete data?._id;
      delete data?.slug;
      delete data?.status;

      return apiCallV2({
        method: 'PUT',
        url,
        data,
        signal: controller.signal,
      });
    },
  });

  return mutation;
};

/**
 * Calls the API to get chart data for the admin dashboard of seller for a given type and time range.
 * @param {string} type - The type of chart data to retrieve (e.g. "products", "orders").
 * @param {number} start_date - The start date of the time range to retrieve data for, in Unix timestamp format.
 * @param {number} [end_date=now] - The end date of the time range to retrieve data for, in Unix timestamp format. Defaults to the current date and time.
 * @returns {Promise<object>} - A Promise that resolves to an object containing the chart data.
 */
export async function getSellerDashboardChart(data) {
  const controller = new AbortController();
  const url = `selleradmin/get_cards_chart`;
  const headers = {};
  const body = {
    ...data,
    start_date: data.start_date || sevenDaysAgo,
    end_date: data.end_date || now,
  };

  return await apiCall(url, body, headers, controller.signal);
}

/**
 * Get seller admin details based on `admin_token`
 * @param {object} data - an object contain key-value as filters
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetSellerAdminDetails = (data = {}, key) => {
  const controller = new AbortController();

  const url = 'selleradmin/get';
  const headers = {};

  const body = {
    ...data,
  };

  return useQuery(
    [key],
    () => apiCall(url, body, headers, controller.signal),
    config,
  );
};

/**
 * Upload profile image for seller based on `admin_token` using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Object with image files
 * admin_profile (required)
 * @return {Object} The object with data, status, error, etc.
 */
export const useUploadImageSellerAdmin = () => {
  const controller = new AbortController();
  const mutation = useMutation((data) => {
    const url = 'selleradmin/change_profile_pic';
    const headers = {};
    return apiCallFile(url, data, headers, controller.signal);
  });

  return mutation;
};

/**
 * Get all approved sellers with optional filter and pagination
 * @param {object} data - an object contain key-value as filters
 *  page, limit
 * @param {string} key - an string name of useQuery call
 * @param {boolean} isEnabled - a bool value to enable/disable useQuery call
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */
export const useGetApprovedSellers = (data = {}, key, isEnabled) => {
  const controller = new AbortController();
  const url = 'selleradmin/get_all_seller';
  const headers = {};

  const body = {
    ...data,
  };

  return useQuery({
    queryKey: [...key],
    queryFn: () => apiCall(url, body, headers, controller.signal),

    ...config,
    enabled: isEnabled,
  });
};

/**
 * Get a seller
 * @param {object} data - an object contain key-value as filters
 *  admin_id (required)
 * @returns {<Object>} - An object contain data, error, isLoading, status...
 */

/**
 * Add new seller using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * name (required), lname (required), address (required), email (required), phone_no (required), password (required), access (required)
 * @return {Object} The object with data, status, error, etc.
 */
export const useAddSeller = () => {
  const controller = new AbortController();
  return useMutation({
    mutationFn: (data = {}) => {
      const url = 'admin/sellers/register';
      return apiCallV2({
        method: 'post',
        url,
        data,
        signal: controller.signal,
      });
    },
  });
};

/**
 * Update seller using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * admin_id (required), name, lname, address, email, phone_no, password, store_name, profile_pic
 * bank_name, bank_account_holder_name, bank_account_number, IfSC_code, accepted, reject_message
 * @return {Object} The object with data, status, error, etc.
 */
export const useUpdateSellerByAdmin = () => {
  const controller = new AbortController();
  return useMutation({
    mutationFn: (data = {}) => {
      const url = 'admin/sellers/' + data?.id;
      return apiCallV2({
        method: 'put',
        url,
        data: { ...data, id: undefined },
        signal: controller.signal,
      });
    },
  });
};
export const useUpdateSystemUser = () => {
  const controller = new AbortController();
  return useMutation({
    mutationFn: (data = {}) => {
      const url = 'admin/system-users/' + data?.id;
      return apiCallV2({
        method: 'put',
        url,
        data: { ...data, id: undefined },
        signal: controller.signal,
      });
    },
  });
};

/**
 * Delete seller using `useMutation` from `@tanstack/react-query`
 * @param {Object} data - Optional data object to send with the request
 * admin_id (required), status: 2 (required)
 * @return {Object} The object with data, status, error, etc.
 */
export const useDeleteSellerByAdmin = () => {
  const controller = new AbortController();

  const mutation = useMutation({
    mutationFn: (data = {}) => {
      const url = 'selleradmin/update_seller_from_Admin';
      const headers = {};
      const body = {
        ...data,
        status: 2,
      };

      return apiCall(url, body, headers, controller.signal);
    },
  });

  return mutation;
};

// Export sellers to Excel
export async function exportSellers(data = {}) {
  const controller = new AbortController();
  const url = 'selleradmin/export_excel';
  const body = {
    ...data,
  };
  const headers = {};

  return await apiCall(url, body, headers, controller.signal);
}

export const useAdminSeller = (params) =>
  useQuery({
    queryKey: ['useAdminSeller'],
    queryFn: () =>
      apiCallV2({ url: 'admin/sellers', method: 'get', params: params }),
  });
