import { useGetOrderPackingSlip, useSyncTrackingStatus } from 'apis/OrdersApi';
import { ShippingInformationTypeEnum } from 'constants/orders';
import { formatDate } from 'helpers/date';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Card, Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import IconFA from 'components/common/IconFA';
import { onCopyToClipBoard } from 'helpers/utils';
import { OrderStatusEnum } from 'types';

export const OrderDetailsHeader = React.memo(
  ({
    data: { code: orderUid, created_on, updated_on, order_status, shipment },
    handleCancelOrder,
    handlePrintPackingSlip,
  }) => {
    const { t } = useTranslation();
    // Get current order
    const { orderId } = useParams();
    const createdOn = formatDate(created_on);
    const updatedOn = formatDate(updated_on);

    // Get packing slip URL
    const { mutate: syncTrackingStatus, isPending: isSyncing } =
      useSyncTrackingStatus(orderId);

    const hideSyncTrackingStatus =
      order_status !== OrderStatusEnum.SHIPPED ||
      shipment?.type === ShippingInformationTypeEnum.Custom;
    const hideCancelOrder =
      order_status !== OrderStatusEnum.PENDING &&
      order_status !== OrderStatusEnum.PROCESSING;
    const showPrintShippingLabel = shipment?.shippo_transaction?.label_url;

    return (
      <div className="">
        <div className="d-flex flex-row align-items-center">
          <Card.Title className="d-flex w-100 gap-2 align-items-center">
            <h4 className="mb-0">{`${t('orders.order')} # ${orderUid}`}</h4>
            <IconFA
              icon="copy"
              className={' text-secondary'}
              type="light"
              onClick={() => onCopyToClipBoard(orderUid)}
            />
          </Card.Title>

          <Dropdown
            drop="start"
            className="d-inline-block align-bottom ms-md-3"
          >
            <Dropdown.Toggle
              as={Button}
              variant="falcon-default"
              size="sm"
              className="dropdown-caret-none"
            >
              {t('orders.actions')}
              <i
                className={`fa-solid fa-chevron-down ms-1`}
                style={{ fontSize: 11 }}
              ></i>
            </Dropdown.Toggle>
            <Dropdown.Menu align="start">
              <Dropdown.Item onClick={handlePrintPackingSlip} rel="noreferrer">
                {t('orders.print_packing_list')}
              </Dropdown.Item>
              {showPrintShippingLabel && (
                <Dropdown.Item
                  href={shipment?.shippo_transaction?.label_url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('orders.print_shipping_label')}
                </Dropdown.Item>
              )}
              <Dropdown.Item
                onClick={syncTrackingStatus}
                hidden={hideSyncTrackingStatus || isSyncing}
              >
                {t('orders.sync_tracking_status')}
              </Dropdown.Item>
              {!hideCancelOrder && <Dropdown.Divider />}
              <Dropdown.Item
                onClick={handleCancelOrder}
                className="text-danger"
                hidden={hideCancelOrder}
              >
                {t('orders.cancel_order')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <p className="fs--1 my-2">
          {createdOn}{' '}
          <span className="fs--2">
            {updatedOn !== 0 && (
              <>
                {' '}
                {/* &#x2022; {t('general.updated_date')}: {updatedOn}{' '} */}
              </>
            )}
          </span>
        </p>
      </div>
    );
  },
);

OrderDetailsHeader.propTypes = {
  data: PropTypes.object.isRequired,
};
