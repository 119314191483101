import { useQueryClient } from '@tanstack/react-query';
import { useUpdateUserByAdmin } from 'apis/UsersApi';
import { AddEditModal } from 'components/common/AddEditModal';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import TableFooter from 'components/common/advance-table/TableFooter';
import TableSearchBox from 'components/common/advance-table/TableSearchBox';
import TableWrapper from 'components/common/advance-table/TableWrapper';
import CardDropdown from 'components/common/CardDropdown';

import IconButton from 'components/common/IconButton';
import StatusBadge from 'components/common/StatusBadge';
import { changeStatusDropdown, deleteDropdown } from 'helpers/mutation-utils';
import { currencyFormatter, dateFormatter } from 'helpers/utils';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { UserFormModal, UserListHeader } from '.';
import { userKeys } from '../queryKeys';
import { FilterCanvasLayout } from './FilterCanvasLayout';
import SortBtn from 'components/common/buttons/SortBtn';
import { useDeleteCustomer } from '../../../apis/CustomersApi';
import { toast } from 'react-toastify';
import DeleteWarningModal from '../../../components/common/DeleteWarningModal';
import DeleteCustomerModal from './DeleteCustomerModal';

export const getCustomerTitle = (title) => {
  switch (title) {
    case 'nail_supply':
      return t('customer.nail_supply');
    case 'nail_tech':
      return t('customer.nail_tech');
    case 'salon_owner':
      return t('customer.salon_owner');
    default:
      return t('customer.other');
  }
};

export const UserList = ({
  data,
  total,
  limit,
  page,
  handlePaginationChange,
}) => {
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { mutate, mutateAsync } = useUpdateUserByAdmin();
  const { mutate: deleteCustomer, isPending: deletingCustomer } =
    useDeleteCustomer();

  const userInit = {
    f_name: '',
    l_name: '',
    email: '',
    phone: '',
  };

  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);

  const [userToDelete, setUserToDelete] = useState(null);
  const [showDeleteWarning, setShowDeleteWarning] = useState(false);
  const [showCanvasFilter, setShowCanvasFilter] = useState(false);

  const handleAddButton = () => setUser(userInit);
  const handleEditDropdownBtn = (row) => setUser(row.original);
  const handleDeleteDropdownBtn = (row) => setUserToDelete(row.original);

  const handleFilterBtn = () => setShowCanvasFilter(true);
  const handleCloseWarning = () => setUserToDelete(null);

  // Change status button
  const handleChangeStatus = (row) => {
    const onSuccess = () => queryClient.invalidateQueries(userKeys.lists());
    changeStatusDropdown(mutate, 'user_id', row.original?._id, onSuccess);
  };

  const onDelete = (data, reason) => {
    deleteCustomer(
      { id: userToDelete?._id, reason },
      {
        onSuccess: () => {
          toast.success(t('general.delete_success'));
          setUserToDelete(null);
          queryClient.invalidateQueries(userKeys.lists());
        },
      },
    );
    // deleteDropdown(mutate, 'user_id', userToDelete?._id, onSuccess);
  };

  const columns = useMemo(
    () => [
      {
        Header: t('general.name'),
        disableSortBy: true,
        Cell: ({ row }) => {
          const { first_name, last_name } = row.original;
          const fullName =
            first_name && last_name ? `${first_name} ${last_name}` : undefined;
          return (
            <Link to={`/customers/${row.original?._id}`}>
              {fullName ? fullName : <>--</>}
            </Link>
          );
        },
      },
      {
        accessor: (row) => (row.phone_number ? row.phone_number : '--'),
        Header: t('register.form.phone'),
        disableSortBy: true,
      },
      {
        accessor: (row) => (row.email ? row.email : '--'),
        Header: t('register.form.email'),
      },
      {
        accessor: 'paid',
        Header: t('orders.paid'),
        Cell: ({ row }) => {
          const { total_order, total_sale } = row?.original?.sale_report;
          return (
            <>
              <div>
                {total_sale
                  ? `${total_order} ${t('orders.orders').toLowerCase()}`
                  : '--'}
              </div>
              <div>
                {total_sale ? currencyFormatter.format(total_sale) : '--'}
              </div>
            </>
          );
        },
      },
      {
        accessor: 'unpaid',
        Header: t('orders.unpaid'),
        Cell: ({ row }) => {
          const { total_unpaid_order, total_unpaid_sale } =
            row?.original?.sale_report;
          return (
            <>
              <div>
                {total_unpaid_order
                  ? `${total_unpaid_order} ${t('orders.orders').toLowerCase()}`
                  : '--'}
              </div>
              <div>
                {total_unpaid_sale
                  ? currencyFormatter.format(total_unpaid_sale)
                  : '--'}
              </div>
            </>
          );
        },
      },
      {
        accessor: (row) => getCustomerTitle(row.title_type),
        Header: t('filter.role'),
      },
      {
        accessor: 'referred_by',
        Header: t('customer.referred_by'),
        Cell: ({ row }) => {
          const { referred_by } = row?.original;
          const fullName = referred_by
            ? `${referred_by?.first_name} ${referred_by?.last_name}`
            : undefined;
          return fullName ? (
            <Link to={`/customers/${referred_by?._id}`}>{fullName}</Link>
          ) : (
            <>--</>
          );
        },
      },
      {
        accessor: 'created_by',
        Header: t('general.created_by'),
        Cell: ({ row }) => {
          const { created_by } = row?.original;
          const fullName = created_by
            ? `${created_by?.first_name} ${created_by?.last_name}`
            : undefined;
          return fullName ? (
            // <Link to={`/system-users/${created_by?._id}`}>{fullName}</Link>
            fullName
          ) : (
            <>--</>
          );
        },
      },
      {
        accessor: (row) => dateFormatter(row?.created_on),
        Header: t('general.created_date'),
      },
      {
        accessor: 'status',
        Header: t('general.status'),
        headerProps: { className: 'text-center', width: '100px' },
        cellProps: { className: 'fs-0' },
        Cell: ({ row }) => {
          const { status } = row?.original;
          return <StatusBadge status={status} />;
        },
      },
      {
        id: 'action',
        disableSortBy: true,
        cellProps: { className: 'text-end' },
        Cell: ({ row }) => {
          return (
            <CardDropdown iconClassName="fs--1">
              <div className="py-2">
                <Dropdown.Item onClick={() => handleEditDropdownBtn(row)}>
                  {t('general.edit')}
                </Dropdown.Item>
                <Dropdown.Divider as="div" />
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => {
                    setUserToDelete(row?.original);
                  }}
                >
                  {t('general.delete')}
                </Dropdown.Item>
              </div>
            </CardDropdown>
          );
        },
      },
    ],
    [],
  );

  useEffect(() => {
    setShowDeleteWarning(!!userToDelete);
    setShowModal(!!user);
  }, [userToDelete, user]);

  return (
    <>
      <TableWrapper
        selection
        columns={columns}
        data={data}
        selectionColumnWidth={30}
        pageSize={limit}
        pageIndex={page - 1}
        handlePaginationChange={handlePaginationChange}
        pageCount={Math.ceil(total / limit)}
      >
        <Card>
          <Card.Header>
            <UserListHeader
              table
              count={data?.length}
              mutateAsync={mutateAsync}
              handleAddButton={handleAddButton}
            />
            <Row className="flex-between-center mt-3">
              <Col xs="auto" className="flex-1 pe-0">
                <TableSearchBox searchParams="search" table />
              </Col>
              <Col xs="auto">
                <div className="d-flex gap-3 mt-lg-0">
                  <IconButton
                    variant="falcon-default"
                    size="sm"
                    icon="filter"
                    transform="shrink-3"
                    onClick={handleFilterBtn}
                  >
                    <span className="d-none d-sm-inline-block">
                      {t('general.filter')}
                    </span>
                  </IconButton>
                  <SortBtn />
                </div>
              </Col>
            </Row>
          </Card.Header>

          <Card.Body className="p-0">
            {data?.length ? (
              <AdvanceTable
                table
                headerClassName="bg-200 text-900 text-nowrap align-middle"
                rowClassName="align-middle white-space-nowrap"
                tableProps={{
                  size: 'sm',
                  hover: true,
                  className: 'fs--1 mb-0 overflow-hidden',
                }}
              />
            ) : (
              <div className="h5 m-3 text-center">{t('general.no_data')}</div>
            )}
          </Card.Body>

          <Card.Footer>
            <TableFooter
              pageSize={limit}
              rowCount={total}
              table
              rowInfo
              navButtons
              rowsPerPageSelection
            />
          </Card.Footer>
        </Card>
      </TableWrapper>

      {/* Add/edit modal */}
      <AddEditModal
        onHide
        value={user}
        setValue={setUser}
        show={showModal}
        setShow={setShowModal}
        size="lg"
        ValueForm={UserFormModal}
      />

      {/* Delete warning */}
      <DeleteCustomerModal
        isLoading={deletingCustomer}
        name={
          !userToDelete?.first_name && !userToDelete?.first_name
            ? t('orders.customer').toLowerCase()
            : `${userToDelete?.first_name || '-'} ${
                userToDelete?.last_name || '-'
              }`
        }
        onShow={showDeleteWarning}
        onClose={handleCloseWarning}
        onDelete={onDelete}
      />

      <FilterCanvasLayout
        key="end"
        placement="end"
        show={showCanvasFilter}
        setShow={setShowCanvasFilter}
      />
    </>
  );
};

UserList.propTypes = {
  data: PropTypes.array.isRequired,
};
