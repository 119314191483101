import { currencyFormatter } from 'helpers/utils';
import PropTypes from 'prop-types';
import React from 'react';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const GrandTotalView = ({ pricing, orderStatus }) => {
  const { t } = useTranslation();

  const totalItems = pricing?.items?.reduce(
    (acc, item) => acc + item.quantity,
    0,
  );

  const itemsTotal = pricing?.items_total;
  const itemsDiscount = pricing?.items_discount;
  const subTotal = pricing?.sub_total;
  const sellerCouponAmount =
    pricing?.coupons?.find((item) => item.provider === 'seller')
      ?.discount_amount || 0;
  const nailzyCouponAmount =
    pricing?.coupons?.find((item) => item.provider === 'nailzy')
      ?.discount_amount || 0;
  const sellerCouponCode =
    pricing?.coupons?.find((item) => item.provider === 'seller')?.code || '';
  const nailzyCouponCode =
    pricing?.coupons?.find((item) => item.provider === 'nailzy')?.code || '';
  const shipping = pricing?.shipping;
  const shippingProvider =
    pricing?.shipping_rate?.provider === 'nailzy'
      ? ''
      : pricing?.shipping_rate?.provider;
  const serviceLevel = pricing?.shipping_rate?.servicelevel_name;
  const estimatedDays = pricing?.shipping_rate?.estimated_days;
  const tax = pricing?.sales_tax;
  const taxRate = pricing?.sales_tax_rates;
  const total = pricing?.total;

  const priceList = [
    {
      label: t('orders.items_total'),
      value: currencyFormatter.format(itemsTotal),
      rawValue: itemsTotal,
      hide: !!(itemsDiscount === 0),
    },
    {
      label: t('orders.items_discount'),
      value: currencyFormatter.format(-itemsDiscount),
      rawValue: itemsDiscount,
      className: 'text-danger',
      hide: !!(itemsDiscount === 0),
    },
    {
      label: t('orders.subtotal'),
      value: currencyFormatter.format(subTotal),
      rawValue: subTotal,
    },
    {
      label: t('orders.seller_coupon'),
      value: currencyFormatter.format(-sellerCouponAmount),
      rawValue: sellerCouponAmount,
      info: sellerCouponCode,
      className: 'text-danger',
      hide: !!(sellerCouponAmount === 0),
    },
    {
      label: t('orders.nailzy_coupon'),
      value: currencyFormatter.format(-nailzyCouponAmount),
      rawValue: nailzyCouponAmount,
      info: nailzyCouponCode,
      className: 'text-danger',
      hide: !!(nailzyCouponAmount === 0),
    },
    {
      label: `${t('orders.shipping')}`,
      value: currencyFormatter.format(shipping),
      rawValue: shipping,
      info: pricing?.shipping_rate ? (
        `${shippingProvider} ${serviceLevel} (${estimatedDays} ${t(
          'orders.business_days',
        )})`
      ) : (
        <span className="text-danger">{t('general.to_be_confirmed')}</span>
      ),
    },
    {
      label: `${t('orders.taxes')}`,
      value: currencyFormatter.format(tax),
      rawValue: tax,
      info: `${taxRate * 100}%`,
      hide: !!(tax === 0),
    },
    {
      label: t('orders.total'),
      value: currencyFormatter.format(total),
      rawValue: total,
      info: `${totalItems} ${(totalItems > 1
        ? t('orders.items')
        : t('orders.item')
      ).toLowerCase()}`,
      bold: true,
    },
  ];

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5>{t('orders.summary')}</h5>
        <Row className="g-0 justify-content-end text-black">
          <Col xs="auto">
            <Table borderless size="sm">
              <tbody>
                {priceList?.map((price, index) => {
                  return (
                    <tr
                      key={index}
                      className={`fs-0 text-black border-bottom border-200 ${
                        price?.hide ? 'd-none' : ''
                      }`}
                    >
                      <td className="px-4">
                        <span
                          className="fw-medium"
                          style={
                            price?.bold
                              ? {
                                  fontWeight: '600',
                                  color: 'black',
                                }
                              : {}
                          }
                        >
                          {price?.label}
                        </span>
                      </td>
                      <td className="pe-4">
                        <span>{price?.info}</span>
                      </td>
                      <td className="text-end">
                        <span
                          className={`fw-medium text-end ${price.className}`}
                          style={
                            price?.bold
                              ? {
                                  fontWeight: '600',
                                  color: 'black',
                                }
                              : {}
                          }
                        >
                          {price?.value}
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

GrandTotalView.propTypes = {
  data: PropTypes.object.isRequired,
};
