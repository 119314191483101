import React, { ReactNode } from 'react';
type PropType = {
  skeleton?: ReactNode;
  children?: ReactNode;
  className?: string;
  show?: boolean;
};
const SkeletonWrapper = (props: PropType) => {
  // ### CONSTANTs
  const { skeleton, className = '', show = false, children } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return <div className={className}>{show ? skeleton : children}</div>;
};
export default SkeletonWrapper;
