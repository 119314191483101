import { ReactNode } from 'react';
import { BreadCrumsProps } from 'types';
import useDocumentTitle from 'hooks/useDocumentTitle';
import BreadCrumbs from './BreadCrumbs';

type Props = {
  children?: ReactNode;
  breadCrumds: BreadCrumsProps[];
  title?: string;
};

const PageLayout = ({ breadCrumds, children, title = '' }: Props) => {
  useDocumentTitle(title);
  return (
    <>
      <BreadCrumbs data={breadCrumds} />
      {children}
    </>
  );
};
export default PageLayout;
