import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useQueryConfig } from 'config';
import { AuthProvider } from 'contexts/AuthContext';
import { TopLoadingProvider } from 'contexts/TopLoadingContext';
import 'react-datepicker/dist/react-datepicker.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.min.css';
import store from 'reduxStore';
import './App.scss';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import Layout from './layouts/Layout';
import { createTheme, MantineProvider } from '@mantine/core';
import theme from './mantineTheme';
import { ModalsProvider } from '@mantine/modals';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: useQueryConfig,
  },
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <AuthProvider>
          <TopLoadingProvider>
            <Router basename={process.env.PUBLIC_URL}>
              <MantineProvider theme={theme}>
                <ModalsProvider>
                  <Layout />
                </ModalsProvider>
              </MantineProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </Router>
          </TopLoadingProvider>
        </AuthProvider>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
