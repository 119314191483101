import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import { useToggle } from '@mantine/hooks';
import {
  TextInputProps,
  TextInput,
  Popover,
  Button,
  Group,
  Flex,
  Text,
  Space,
  ScrollArea,
} from '@mantine/core';
import { map, pick, compose, prop, toLower, uniqBy, head } from 'rambda';
import 'react-international-phone/style.css';
import {
  CountryIso2,
  defaultCountries,
  FlagEmoji,
  parseCountry,
  usePhoneInput,
} from 'react-international-phone';
import IconFA from 'components/common/IconFA';

const makeOptions = map(
  compose(pick(['iso2', 'name', 'dialCode']), parseCountry),
);

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
  selectedCountryIso: CountryIso2;
  setCountryIso: (countryIso2: CountryIso2) => void;
  isVisible?: boolean;
}

const DROPDOWN_MAX_HEIGHT = 200;
const DROPDOWN_PADDING_Y = 8;

const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(
  ({ selectedCountryIso, setCountryIso, isVisible, ...props }, ref) => {
    const viewportRef = useRef<HTMLDivElement>(null);

    const options: any = useMemo(() => makeOptions(defaultCountries), []);
    // Maps the first occurrence of the first character of each country
    // name to it's array index (in 'options' array)
    const charIndex = useMemo(() => {
      const charMap: any = map(
        compose(toLower, head as any, prop('name')),
        options,
      );
      const itr = charMap.map((char: any, index: number) => ({ char, index }));
      const uniqs = uniqBy(prop('char'))(itr);

      return uniqs.reduce(
        (obj: Record<string, number>, { char, index }: any) => {
          obj[char] = index;
          return obj;
        },
        {},
      ) as Record<string, number>;
    }, [options]);

    const scrollWhenTyped = useCallback(
      (evt: KeyboardEvent) => {
        const char = evt.key.toLowerCase();
        if (!viewportRef.current || !char.match(/[a-z]/i)) {
          return;
        }

        const optionHeight =
          (viewportRef.current.scrollHeight - 2 * DROPDOWN_PADDING_Y) /
          options.length;

        const scrollFromTop = optionHeight * charIndex[char];

        if (viewportRef.current) {
          viewportRef.current.scrollTo({ top: scrollFromTop });
        }
      },
      [charIndex, options.length],
    );

    useEffect(() => {
      if (isVisible) {
        window.addEventListener('keydown', scrollWhenTyped);
      }

      return () => {
        if (isVisible) {
          window.removeEventListener('keydown', scrollWhenTyped);
        }
      };
    }, [isVisible, scrollWhenTyped]);

    return (
      <ScrollArea
        ref={ref}
        viewportRef={viewportRef}
        h={DROPDOWN_MAX_HEIGHT}
        tabIndex={0}
        {...props}
      >
        <Space h={DROPDOWN_PADDING_Y} />

        {options.map((option: any) => (
          <Flex
            tabIndex={-1}
            key={option.iso2}
            w="100%"
            gap={8}
            align="center"
            role="button"
            px={12}
            py={DROPDOWN_PADDING_Y}
            data-selected={option.iso2 === selectedCountryIso}
            onClick={() => setCountryIso(option.iso2)}
            style={(theme: any) => ({
              cursor: 'pointer',
              '&:hover': {
                backgroundColor: theme.colors.blue,
                color: theme.colors.blue[2],
              },
              "&[data-selected='true']": {
                backgroundColor: theme.colors.orange,
                color: theme.colors.orange[2],
              },
            })}
          >
            <FlagEmoji iso2={option.iso2} size={20} />
            <Text size="sm" style={{ flex: 1 }}>
              {option.name}
            </Text>
            <Text size="sm">+{option.dialCode}</Text>
          </Flex>
        ))}
        <Space h={DROPDOWN_PADDING_Y} />
      </ScrollArea>
    );
  },
);
type Props = TextInputProps & {
  onChangeText?: (txt: string) => void;
};

const PhoneInput: React.FC<Props> = ({
  value,
  onChangeText,
  defaultValue,
  onChange,
  ...props
}) => {
  const [dropdownOpened, toggleDropdown] = useToggle();

  const { phone, handlePhoneValueChange, inputRef, country, setCountry } =
    usePhoneInput({
      countries: defaultCountries,
      value: defaultValue as any,
      onChange: ({ phone }) => {
        onChange?.(phone as any);
        onChangeText?.(phone?.replace(/[^\d+]/g, '') as any);
      },
    });

  return (
    <Popover
      opened={dropdownOpened}
      position="bottom-end"
      width="target"
      shadow="md"
    >
      <Popover.Target>
        <TextInput
          ref={inputRef}
          value={phone}
          defaultValue={'+1'}
          onChange={handlePhoneValueChange}
          leftSectionWidth={60}
          leftSection={
            <Button
              variant="transparent"
              px={8}
              onFocus={() => toggleDropdown()}
              onBlur={() => toggleDropdown(false)}
            >
              <Group gap={'xs'}>
                <FlagEmoji iso2={country} size={20} />
                <IconFA
                  icon="chevron-down"
                  className={' text-xs text-neutral-500'}
                />
              </Group>
            </Button>
          }
          {...props}
        />
      </Popover.Target>

      <Popover.Dropdown p={0}>
        <Dropdown
          setCountryIso={setCountry}
          selectedCountryIso={country}
          isVisible={dropdownOpened}
        />
      </Popover.Dropdown>
    </Popover>
  );
};
export default PhoneInput;
