import { icon } from '@fortawesome/fontawesome-svg-core';
import {
  AppShell,
  Burger,
  Flex,
  Group,
  NavLink,
  ScrollArea,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import IconFA from 'components/common/IconFA';
import Logo from 'components/common/Logo';
import ProfileDropdown from 'components/navbar/top/ProfileDropdown';
import { APP_ROLES } from 'constants/constants';
import { useAuth } from 'contexts/AuthContext';
import AppContext from 'contexts/Context';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import adminMenuRoutes from 'routes/adminMenuRoutes';
import sellerRoutes from 'routes/sellerMenuRoutes';

const MainLayout = () => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();

  const { hash, pathname } = useLocation();
  const [opened, { toggle }] = useDisclosure();
  const [active, setActive] = useState(2);
  // const isChat = pathname.includes('chat');
  const systemUserRoutes = useMemo(() => {
    const actions = user?.permission?.actions;
    let array = [];
    for (let i = 0; i < adminMenuRoutes.length; i++) {
      const route = adminMenuRoutes[i];
      if (!route?.children) {
        if (actions?.includes(`${route?.mainAction}.GET`)) {
          array.push(route);
        }
      } else {
        let children = [];
        for (let j = 0; j < route?.children.length; j++) {
          const child = route?.children[j];
          if (actions?.includes(`${child?.mainAction}.GET`)) {
            children.push(child);
          }
        }
        if (children?.length) {
          array.push({ ...route, children });
        }
      }
    }
    return array;
  }, [adminMenuRoutes, user]);
  const {
    config: { isFluid, navbarPosition },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const data = useMemo(
    () =>
      user
        ? user?.type === APP_ROLES.seller
          ? sellerRoutes
          : user?.permission?.is_super_admin
          ? adminMenuRoutes
          : systemUserRoutes
        : [],
    [user, systemUserRoutes],
  );
  useEffect(() => {
    setTimeout(() => {
      if (hash) {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
      }
    }, 0);
  }, []);

  useEffect(() => {
    onChangeRoute();
  }, [location.pathname, user]);

  const curentRoute = useMemo(
    () => `/${location.pathname.split('/')?.[1] || ''}`,
    [location.pathname, user],
  );
  const onChangeRoute = () => {
    for (let i = 0; i < data.length; i++) {
      const route = data[i];
      if (route?.children) {
        for (let y = 0; y < route?.children.length; y++) {
          const child = route?.children[y];
          if (child?.to === curentRoute) {
            setActive(child?.label);
          }
        }
      } else if (route?.to === curentRoute) {
        setActive(route?.label);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!isLoggedIn) return <Navigate to="/login" />;

  const onNavigate = (item) => {
    if (item?.to && item?.to !== location?.pathname) {
      setActive(item?.label);
      toggle();
      navigate(item.to);
    }
  };
  const items = data.map((item, index) => (
    <NavLink
      key={item.label}
      active={item.label === active}
      label={item.label}
      // description={item.description}
      defaultOpened={item?.children?.find?.((x) => x?.to === curentRoute)}
      rightSection={item.rightSection}
      leftSection={
        <div
          style={{
            width: 25,
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <IconFA icon={item?.icon} />
        </div>
      }
      onClick={() => {
        onNavigate(item, item.label === active);
      }}
      disabled={item?.disabled}
    >
      {item?.children?.length
        ? item?.children?.map((chid, i) => (
            <NavLink
              leftSection={
                <div style={{ width: 20, alignItems: 'center' }}>
                  {/* <IconFA icon={chid?.icon} /> */}
                </div>
              }
              active={chid?.label === active}
              onClick={() => {
                onNavigate(chid);
              }}
              key={i}
              label={chid?.label}
            />
          ))
        : null}
    </NavLink>
  ));

  return (
    <AppShell
      zIndex={1}
      header={{ height: 60 }}
      navbar={{ width: 250, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header bg={'white'}>
        <Flex justify={'space-between'} align={'center'}>
          <Group h="100%" px="md">
            <Burger
              opened={opened}
              onClick={toggle}
              hiddenFrom="sm"
              size="sm"
            />
            <Logo width={100} className={undefined} textClass={undefined} />
          </Group>
          <Group h="100%" px="md">
            <ProfileDropdown />
          </Group>
        </Flex>
      </AppShell.Header>
      <AppShell.Navbar
        zIndex={2}
        className="bg-[#ffffff] md:!bg-transparent"
        bd={0}
        pt={'lg'}
      >
        <ScrollArea pb={'xs'} h={'100%'}>
          {items}
        </ScrollArea>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default MainLayout;
