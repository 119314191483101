import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/pro-solid-svg-icons';
import { ActionIcon, Checkbox, Flex, Grid, Text } from '@mantine/core';
import { PermissionAction, PermissionActionItem, RequestMethod } from 'types';
import IconFA from 'components/common/IconFA';
import React, { ReactNode, useEffect, useMemo, useState } from 'react';
type PropType = {
  action?: PermissionActionItem;
  selectedActions?: string[];
  onChange?: (values: string[]) => void;
  isSuperAdmin?: boolean;
};
const PermissionActionsCard = (props: PropType) => {
  // ### CONSTANTs
  const [expanded, setExpanded] = useState(false);
  const { action, onChange, selectedActions = [], isSuperAdmin } = props;
  // ### FUNCTIONs
  const cacheSelected = useMemo(
    () => new Set(selectedActions),
    [selectedActions],
  );

  const [isCheckAllCard, indeterminateAllCard, totalMethods, selectedMethods] =
    useMemo(() => {
      let array = [];
      let allMethod = [];
      let total = 0;
      for (let i = 0; i < (action?.actions?.length || 0); i++) {
        const act = action?.actions?.[i];
        if (act?.methods?.length) {
          total += act?.methods?.length || 0;
          for (let j = 0; j < act.methods.length; j++) {
            const method = act.methods[j];
            allMethod.push(`${act.path}.${method}`);
            if (cacheSelected.has(`${act.path}.${method}`)) {
              array.push(`${act.path}.${method}`);
            }
          }
        }
      }
      return [
        !!array?.length && total === array?.length,
        !!array?.length && array?.length < total,
        allMethod,
        array,
      ];
    }, [action, cacheSelected]);
  const onCheckAllCard = (checked: boolean) => {
    let array = [...selectedActions]?.filter(
      (x) => !selectedMethods.includes(x),
    );
    if (checked) {
      array = [...array, ...totalMethods];
    }
    onChange?.(array);
  };
  const onCheckAll = (item: PermissionAction, checked: boolean) => {
    if (item?.methods) {
      let array = [...selectedActions];
      for (let i = 0; i < item?.methods?.length; i++) {
        const method = item?.methods[i];
        const actionText = `${item?.path}.${method}`;
        array = array.filter((x) => x !== actionText);
        if (checked) {
          array.push(actionText);
        }
      }
      onChange?.(array);
    }
  };
  const onCheck = (
    item: PermissionAction,
    method: RequestMethod,
    checked: boolean,
  ) => {
    const actionText = `${item?.path}.${method}`;
    let array = [...selectedActions];
    if (checked) {
      array.push(actionText);
    } else {
      array = array.filter((x) => x !== actionText);
    }
    onChange?.(array);
  };

  // ### RENDERs
  const renderAction = (act: PermissionAction, index: number) => {
    const selected = {
      GET: cacheSelected.has(`${act.path}.GET`),
      POST: cacheSelected.has(`${act.path}.POST`),
      PUT: cacheSelected.has(`${act.path}.PUT`),
      DELETE: cacheSelected.has(`${act.path}.DELETE`),
    };

    let checkedAll = false,
      indeterminate = false;
    if (
      act?.methods?.length ===
      (selected?.GET ? 1 : 0) +
        (selected?.POST ? 1 : 0) +
        (selected?.PUT ? 1 : 0) +
        (selected?.DELETE ? 1 : 0)
    ) {
      checkedAll = true;
    } else if (
      !(
        !selected?.GET &&
        !selected?.POST &&
        !selected?.PUT &&
        !selected?.DELETE
      )
    ) {
      indeterminate = true;
    }
    return (
      <div
        key={index}
        className={`flex flex-col md:flex-row w-100% mx-2 py-2 items-end md:items-start  ${
          index % 2 === 0 ? 'bg-gray-50' : ''
        }`}
      >
        <Flex className="flex-1" w={'100%'}>
          <Checkbox
            onChange={(e) => onCheckAll(act, e.target.checked)}
            disabled={isSuperAdmin}
            checked={isSuperAdmin || checkedAll}
            indeterminate={indeterminate}
            className="ms-4 me-2"
            size="md"
          />
          <Text>{act?.path || ''}</Text>
        </Flex>
        <Flex className="">
          <div className="justify-center flex w-[100px]">
            {act?.methods?.includes(RequestMethod.GET) ? (
              <Checkbox
                disabled={isSuperAdmin}
                checked={isSuperAdmin || selected?.GET}
                onChange={(e) =>
                  onCheck(act, RequestMethod.GET, e.target.checked)
                }
                size="md"
              />
            ) : null}
          </div>
          <div className="justify-center flex w-[100px]">
            {act?.methods?.includes(RequestMethod.POST) ? (
              <Checkbox
                disabled={isSuperAdmin}
                checked={isSuperAdmin || selected?.POST}
                onChange={(e) =>
                  onCheck(act, RequestMethod.POST, e.target.checked)
                }
                size="md"
              />
            ) : null}
          </div>
          <div className="justify-center flex w-[100px]">
            {act?.methods?.includes(RequestMethod.PUT) ? (
              <Checkbox
                disabled={isSuperAdmin}
                checked={isSuperAdmin || selected?.PUT}
                onChange={(e) =>
                  onCheck(act, RequestMethod.PUT, e.target.checked)
                }
                size="md"
              />
            ) : null}
          </div>
          <div className="justify-center flex w-[100px]">
            {act?.methods?.includes(RequestMethod.DELETE) ? (
              <Checkbox
                disabled={isSuperAdmin}
                checked={isSuperAdmin || selected?.DELETE}
                onChange={(e) =>
                  onCheck(act, RequestMethod.DELETE, e.target.checked)
                }
                size="md"
              />
            ) : null}
          </div>
        </Flex>
      </div>
    );
  };
  return (
    <Grid className="border rounded-md py-3 mb-3">
      <Grid.Col span={{ base: 5 }}>
        <Flex className="ms-4" gap={'sm'}>
          <Checkbox
            key={action?.name}
            disabled={isSuperAdmin}
            indeterminate={indeterminateAllCard}
            checked={isSuperAdmin || isCheckAllCard}
            onChange={(e) => onCheckAllCard(e.target.checked)}
            size="md"
          />
          <Text fw={600}>{action?.name}</Text>
        </Flex>
      </Grid.Col>
      <Grid.Col span={{ base: 7 }} className="relative">
        <div className="absolute right-3">
          <ActionIcon
            color={'gray'}
            variant="subtle"
            onClick={() => setExpanded(!expanded)}
          >
            <IconFA
              icon={expanded ? faChevronUp : faChevronDown}
              type="light"
            />
          </ActionIcon>
        </div>
      </Grid.Col>
      {expanded ? (
        <div className="w-full">
          {action?.actions?.map((act, index) => renderAction(act, index))}
        </div>
      ) : null}
    </Grid>
  );
};
export default PermissionActionsCard;
