import { Anchor, Breadcrumbs as MantineBreadcrumbs, Text } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { t } from 'i18next';
import { Link } from 'react-router-dom';
import { BreadCrumsProps } from 'types';

const BreadCrumbs = ({ data = [] }: { data: BreadCrumsProps[] }) => {
  const renderData = [
    {
      title: (
        <>
          <IconFA icon="home" className=" me-1" type="light" />
          {t('navigation.home')}
        </>
      ),
      href: '/',
    },
    ...data,
  ];
  return (
    <MantineBreadcrumbs className="my-3">
      {renderData?.map((item, index) =>
        index === renderData?.length - 1 ? (
          <Text c="dimmed" key={index}>
            {item?.title}
          </Text>
        ) : (
          <Anchor
            component={Link}
            to={item.href || ''}
            c={'primary'}
            key={index}
          >
            {item?.title}
          </Anchor>
        ),
      )}
    </MantineBreadcrumbs>
  );
};
export default BreadCrumbs;
