import IconFA from 'components/common/IconFA';
import { ShippingInformationTypeEnum } from 'constants/orders';
import { onCopyToClipBoard } from 'helpers/utils';
import OrderStatus from 'pages/orders/components/OrderStatus';
import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import {
  getTrackingUrl,
  getCarrierName,
  currencyFormatter,
} from 'helpers/utils';
import { OrderStatusEnum } from 'types';

export const FulfillSummary = ({ data }) => {
  const { t } = useTranslation();
  const shipment = data?.shipment;
  const isFulfilled =
    data?.order_status !== OrderStatusEnum.PROCESSING &&
    shipment &&
    Object.keys(shipment).length > 0;
  const isShippo = shipment?.type === ShippingInformationTypeEnum.Shippo;
  const isManual = shipment?.type === ShippingInformationTypeEnum.Manual;
  const isCustom = shipment?.type === ShippingInformationTypeEnum.Custom;
  const carrierDetails = {
    url: isShippo
      ? shipment?.shippo_transaction?.tracking_url_provider
      : getTrackingUrl(shipment?.carrier_id, shipment?.tracking_number),
    trackingNumber: isShippo
      ? shipment?.shippo_transaction?.tracking_number
      : shipment?.tracking_number,
    provider: isShippo
      ? `${shipment?.shippo_rate?.provider} ${shipment?.shippo_rate?.servicelevel_name}`
      : `${getCarrierName(shipment?.carrier_id)} ${
          shipment?.shippo_track?.servicelevel?.name || ''
        }`,
    status: isShippo
      ? shipment?.shippo_transaction?.tracking_status
      : shipment?.shippo_track?.tracking_status?.status ||
        shipment?.shippo_track?.tracking_status,
    estimated_days: shipment?.shippo_rate?.estimated_days,
    amount: shipment?.shippo_rate?.amount,
    trackingStatus: shipment?.shippo_track?.tracking_status?.status_details,
  };
  const parcelDetail = isShippo
    ? {
        size: `${shipment?.parcel?.length} ${shipment?.parcel?.unit} x ${shipment?.parcel?.width} ${shipment?.parcel?.unit} x ${shipment?.parcel?.height} ${shipment?.parcel?.unit}`,
        weight: `${shipment?.parcel?.weight} ${shipment?.parcel?.weight_unit}`,
      }
    : undefined;

  return (
    <React.Fragment>
      <div className="d-flex flex-column">
        <OrderStatus status={data?.order_status} className="fs--1" />
      </div>
      {isFulfilled ? (
        <>
          <Row className="fs--1 mx-1 my-3 px-1 py-3 bg-200 rounded-3 gap-4">
            {(isShippo || isManual) && (
              <>
                <Col xs={12} md={'auto'}>
                  <p className="fw-bold mb-1">
                    {isShippo
                      ? t('orders.shipping_label')
                      : t('orders.tracking_number')}{' '}
                  </p>
                  <div className="d-flex align-items-center fw-bold">
                    <Link
                      className="text-primary"
                      target="_blank"
                      to={carrierDetails?.url}
                    >
                      {carrierDetails?.trackingNumber}
                    </Link>
                    <IconFA
                      icon="copy"
                      type="light"
                      onClick={() =>
                        onCopyToClipBoard(carrierDetails?.trackingNumber)
                      }
                    />
                    <SoftBadge pill bg={'info'} className="fs--2">
                      {carrierDetails?.status}
                    </SoftBadge>
                  </div>
                  <p>
                    {carrierDetails?.provider}{' '}
                    {carrierDetails?.servicelevel_name}{' '}
                  </p>
                  <p>{carrierDetails?.trackingStatus}</p>
                  {isShippo && (
                    <>
                      <p>
                        {carrierDetails?.estimated_days}{' '}
                        {t('orders.business_days')}
                      </p>
                      <p>{currencyFormatter.format(carrierDetails?.amount)}</p>
                    </>
                  )}
                </Col>
                {isShippo && (
                  <Col>
                    <p className="fw-bold mb-2">{t('orders.package_info')}</p>
                    <p>
                      {t('orders.size')}: {parcelDetail?.size}
                    </p>
                    <p>
                      {t('orders.mass')}: {parcelDetail?.weight}
                    </p>
                  </Col>
                )}
              </>
            )}

            {isCustom && (
              <Col xs={12} md={'auto'}>
                <p className="fw-bold mb-1 fs--1">
                  {t('orders.custom_fulfillment')}
                </p>
                <p className="fs--1">{shipment?.note ?? ''}</p>
              </Col>
            )}
          </Row>
        </>
      ) : null}
    </React.Fragment>
  );
};

FulfillSummary.propTypes = {
  data: PropTypes.object.isRequired,
  refetch: PropTypes.func,
  user: PropTypes.object,
};
