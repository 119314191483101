import FalconCloseButton from 'components/common/FalconCloseButton';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../components/common/buttons/LoadingButton';
import IconFA from 'components/common/IconFA';

/**
 * @param {bool} remove A boolean value to determine if the modal is for removing or deleting an item.
 * @param {string} name The name of the item to be deleted.
 * @param {bool} onShow A boolean value to detemine if the modal should be shown.
 * @param {func} onClose A function handle closing modal.
 * @param {func} onDelete A function handle deleting item.
 * @param {boolean} isLoading A boolean to determine if action is executing
 * @returns
 */
const DeleteCustomerModal = forwardRef(
  (
    {
      remove,
      name = 'this item',
      onShow,
      onClose: onModalClose,
      onDelete,
      isLoading = false,
      title,
    },
    ref,
  ) => {
    const { t } = useTranslation();
    const [internalShow, setInternalShow] = useState(false);
    const [product, setProduct] = useState(null);
    const [reason, setReason] = useState('');
    useImperativeHandle(ref, () => ({
      show,
      hide,
    }));
    const show = (product) => {
      setInternalShow(true);
      setProduct(product);
    };
    const hide = (product) => {
      setInternalShow(false);
    };
    const action = (() => {
      if (remove && isLoading) return t('general.removing');
      if (remove && !isLoading) return t('general.remove');
      if (!remove && isLoading) return t('general.deleting');
      if (!remove && !isLoading) return t('general.delete');
    })();
    const onClose = () => {
      setInternalShow(false);
      onModalClose?.();
    };
    return (
      <Modal show={internalShow || onShow} onHide={onClose} centered>
        <Modal.Header>
          {title ? (
            <Modal.Title>{title}</Modal.Title>
          ) : (
            <Modal.Title>
              {remove ? t('general.remove') : t('general.delete')}{' '}
              {product?.name || name}?
            </Modal.Title>
          )}
          <FalconCloseButton onClick={onClose} />
        </Modal.Header>
        <Modal.Body className="pb-0">
          {/* {t('general.delete_confirm')}{' '}
          {remove
            ? t('general.remove').toLowerCase()
            : t('general.delete').toLowerCase()}{' '}
          <strong className="text-danger">{product?.name || name}</strong>
        {t('general.cant_undone')} */}
          <div className="text-danger">
            {t('customer.delete_customer_note')}
          </div>
          <div className="fs--1 mt-3">
            {t('customer.delete_customer_reason')}
          </div>
          <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea"
              rows={3}
              value={reason}
              onChange={(e) => setReason(e?.target?.value)}
            />
          </Form.Group>
        </Modal.Body>
        <div className="d-flex flex-row justify-content-end p-3 gap-2">
          <Button
            variant="falcon-default"
            transform="shrink-3"
            onClick={onClose}
          >
            {t('general.cancel')}
          </Button>
          <LoadingButton
            variant="danger"
            disabled={!reason}
            isLoading={isLoading}
            onClick={() => {
              onDelete?.(product, reason);
              setReason('');
            }}
            size="md"
          >
            {isLoading ? null : (
              <IconFA icon="trash-can" className=" me-2" color="white" />
            )}
            <span>{action}</span>
          </LoadingButton>
        </div>
      </Modal>
    );
  },
);

export default DeleteCustomerModal;
