import { Card, Flex, Grid, Skeleton } from '@mantine/core';
import React, { ReactNode } from 'react';
import { SectionFooter, SectionWrapper } from '../CommonSection';
type PropType = {
  children?: ReactNode;
  className?: string;
};
const SectionPageSkeleton = (props: PropType) => {
  // ### CONSTANTs
  const { children, className = '' } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <div className={className}>
      <Skeleton height={20} width={'20%'} radius="xs" mb={'sm'} />
      <Grid>
        <Grid.Col span={{ base: 12, sm: 8 }}>
          <SectionWrapper>
            <Skeleton height={40} width={'15%'} radius="xs" />
            <Skeleton height={15} width={'50%'} radius="xs" />

            <Skeleton height={15} width={'15%'} radius="xs" />
            <Skeleton height={35} width={'100%'} radius="xs" />

            <Skeleton height={15} width={'15%'} radius="xs" className="mt-2" />
            <Skeleton height={35} width={'100%'} radius="xs" />
            <SectionFooter>
              <Flex w={'100%'} justify={'space-between'} align={'flex-end'}>
                <Skeleton height={15} width={300} radius="xs" />
                <Flex gap={'md'}>
                  <Skeleton height={40} width={120} radius="xs" />
                  <Skeleton height={40} width={120} radius="xs" />
                </Flex>
              </Flex>
            </SectionFooter>
          </SectionWrapper>
        </Grid.Col>
        <Grid.Col span={{ base: 12, sm: 4 }}>
          <SectionWrapper>
            <Skeleton height={25} width={'40%'} radius="xs" />
            <Skeleton height={25} width={'20%'} radius="xs" />
            <Skeleton height={20} width={'55%'} radius="xs" />
            <Skeleton height={15} width={'30%'} radius="xs" />
            <Skeleton height={20} width={'55%'} radius="xs" />
            <Skeleton height={15} width={'30%'} radius="xs" />
          </SectionWrapper>
          <SectionWrapper>
            <Skeleton height={25} width={'40%'} radius="xs" />
            <Skeleton height={25} width={'20%'} radius="xs" />
            <Skeleton height={20} width={'55%'} radius="xs" />
            <Skeleton height={15} width={'30%'} radius="xs" />
            <Skeleton height={20} width={'55%'} radius="xs" />
            <Skeleton height={15} width={'30%'} radius="xs" />
          </SectionWrapper>
        </Grid.Col>
      </Grid>
    </div>
  );
};
export default SectionPageSkeleton;
