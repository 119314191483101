import React from 'react';
import IconFA from '../IconFA';
import { getColor } from 'helpers/utils';

const CheckBox = ({ checked, onChange, disabled }) => {
  return (
    <div
      onClick={(e) => {
        if (!disabled) {
          e?.preventDefault();
          onChange?.(!checked);
        }
      }}
    >
      {disabled ? (
        <IconFA icon={'square'} type="light" color={getColor('gray-400')} />
      ) : checked ? (
        <IconFA icon={'square-check'} color={getColor('primary')} />
      ) : (
        <IconFA icon={'square'} type="light" />
      )}
    </div>
  );
};
export default CheckBox;
