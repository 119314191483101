import * as Sentry from '@sentry/react';
import 'helpers/initFA';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import App from './App';
import i18next from './i18n';
import Main from './Main';
import FallBackLayout from 'layouts/FallBackLayout';

Sentry.init({
  enabled: process.env.REACT_APP_ENV !== 'development',
  environment: process.env.REACT_APP_ENV,
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: process.env.REACT_APP_ENV === 'production' ? 0.1 : 0.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/api\.nailzy\.com/],
  // Session Replay
  replaysSessionSampleRate:
    process.env.REACT_APP_ENV === 'production' ? 0.1 : 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate:
    process.env.REACT_APP_ENV === 'production' ? 1.0 : 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.,
});

const container = document.getElementById('main');
const root = createRoot(container as any);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <Sentry.ErrorBoundary fallback={<FallBackLayout />} showDialog>
        <Main>
          <App />
        </Main>
      </Sentry.ErrorBoundary>
    </I18nextProvider>
  </React.StrictMode>,
);
