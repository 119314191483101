import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { apiCallV2 } from './ApiCallV2';
import { dataFetchingQueryConfig } from 'config';
import { toast } from 'react-toastify';
import { t } from 'i18next';
const config = dataFetchingQueryConfig;

export const usePermissionList = (params = {}) => {
  const url = `admin/permissions`;
  return useQuery<any>({
    queryKey: ['usePermissionList'],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
        params,
      }),
    ...config,
  });
};
export const usePermissionDetails = (id?: string) => {
  const url = `admin/permissions/` + id;
  return useQuery<any>({
    queryKey: ['usePermissionDetails', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};
export const usePermissionActions = () => {
  const url = `admin/permissions/actions`;
  return useQuery<any>({
    queryKey: ['usePermissionActions'],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    ...config,
  });
};

export const useUpdatePermission = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: any) => {
      const { id, ...data } = params;
      const url = `admin/permissions/` + id;
      return apiCallV2({
        url,
        method: 'PUT',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.update_success'));
        queryClient.invalidateQueries(['usePermissionList'] as any);
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message);
    },
    ...config,
  });
};
export const useCreatePermission = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const url = `admin/permissions`;
      return apiCallV2({
        url,
        method: 'POST',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.create_success'));
        queryClient.invalidateQueries(['usePermissionList'] as any);
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message);
    },
    ...config,
  });
};

export const useDeletePermission = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) => {
      const url = `admin/permissions/` + id;
      return apiCallV2({
        url,
        method: 'DELETE',
        data: { id },
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.delete_success'));
        queryClient.invalidateQueries(['usePermissionList'] as any);
      }
    },
    onError: (res) => {
      toast.error(t('general.delete_fail'));
    },
    ...config,
  });
};
// SYSTEM USERS
export const useSystemUserList = (params = {}) => {
  const url = `admin/system-users`;
  return useQuery<any>({
    queryKey: ['useSystemUserList', params],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
        params,
      }),
    ...config,
  });
};

export const useSystemUserDetails = (id?: string) => {
  const url = `admin/system-users/` + id;
  return useQuery<any>({
    queryKey: ['useSystemUserDetails', id],
    queryFn: () =>
      apiCallV2({
        url,
        method: 'GET',
      }),
    enabled: !!id,
    ...config,
  });
};

export const useCreateSystemUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: any) => {
      const url = `admin/system-users`;
      return apiCallV2({
        url,
        method: 'POST',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.create_success'));
        queryClient.invalidateQueries(['useSystemUserList'] as any);
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message);
    },
    ...config,
  });
};

export const useUpdateSystemUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: any) => {
      const { _id, ...data } = params;
      const url = `admin/system-users/` + _id;
      return apiCallV2({
        url,
        method: 'PUT',
        data,
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.create_success'));
        queryClient.invalidateQueries(['useSystemUserList'] as any);
      }
    },
    onError: (res: any) => {
      toast.error(res?.description?.message);
    },
    ...config,
  });
};

export const useDeleteSystemUser = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (id: any) => {
      const url = `admin/system-users/` + id;
      return apiCallV2({
        url,
        method: 'DELETE',
        data: { id },
      });
    },
    onSuccess: (res) => {
      if (res?.status) {
        toast.success(t('general.delete_success'));
        queryClient.invalidateQueries(['useSystemUserList'] as any);
      }
    },
    onError: (res) => {
      toast.error(t('general.delete_fail'));
    },
    ...config,
  });
};
