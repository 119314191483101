import { t } from 'i18next';
import { Button, Card, Col, FormControl, Row, Form } from 'react-bootstrap';
import IconFA from '../IconFA';
import { checkPermission, onCopyToClipBoard } from 'helpers/utils';
import SelectInput from '../inputs/SelectInput';
import { useState } from 'react';
import { useGetCustomers } from 'apis/CustomersApi';
import FlagPhoneInput from '../phone-input/FlagPhoneInput';
import { useForm } from 'react-hook-form';
import { getColor, phoneFormatter } from '../../../helpers/utils';
import { UserFormModal } from '../../../pages/customers/components';
import { AddEditModal } from 'components/common/AddEditModal';

const CustomerCard = ({ customer, onSave, isCompleted }) => {
  const { first_name, last_name, email, phone_number } = customer || {};

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({});

  const [tempCus, setTempCus] = useState();
  const [editing, setEditing] = useState(false);
  const [user, setUser] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const isGranted = checkPermission(user, '/customers');
  const { data: getCustomerResponse, isSuccess: isGetCustomerSuccess } =
    useGetCustomers(
      {
        limit: 2000,
        status: 'active',
      },
      isGranted,
    );
  const customers = getCustomerResponse?.data?.results || [];

  const customerOptions = customers?.map((item) => ({
    value: item._id,
    label: `${item.first_name} ${item.last_name}`,
    status: item.status,
  }));
  const onSelectCustomer = (value) => {
    let found = customers?.find((x) => x?._id === value?.value);
    if (found) {
      setTempCus(() => found);
    }
  };
  const onAddCustomer = () => {
    setShowModal(true);
  };

  return (
    <Form onSubmit={handleSubmit(() => {})}>
      <Card className="mb-3">
        <Card.Header>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5>{t('orders.customer')}</h5>
            <div>
              {isCompleted ? null : customer ? (
                editing ? (
                  <div>
                    <Button
                      variant={'falcon-default'}
                      size="sm"
                      className="me-2"
                      onClick={() => setEditing(false)}
                    >
                      {t('general.cancel')}
                    </Button>
                    <Button
                      variant="outline-primary"
                      size="sm"
                      onClick={() => {
                        onSave?.(tempCus);
                        setEditing(false);
                      }}
                    >
                      {t('general.save')}
                    </Button>
                  </div>
                ) : (
                  <Button
                    variant="falcon-default"
                    size="sm"
                    onClick={() => setEditing(true)}
                  >
                    {t('general.change')}
                  </Button>
                )
              ) : (
                <>
                  <Button
                    variant="outline-white"
                    className="me-1 text-primary"
                    size="sm"
                    onClick={() => onAddCustomer()}
                  >
                    <IconFA
                      className={' me-1'}
                      icon="add"
                      type="light"
                      color={getColor('primary')}
                    />
                    {t('general.add')}
                  </Button>
                  <Button
                    disabled={!tempCus}
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      onSave?.(tempCus);
                      setEditing(false);
                    }}
                  >
                    {t('general.save')}
                  </Button>
                </>
              )}
            </div>
          </div>
        </Card.Header>
        <Card.Body className="pt-0">
          {customer && !editing ? (
            <div>
              <div className="fs--1 fw-semi-bold text-black">
                {first_name} {last_name}
              </div>
              {email ? (
                <div className="d-flex flex-row justify-content-between align-items-center fs--1 text-black">
                  <div className="flex-1 overflow-hidden">{email}</div>
                  <IconFA
                    onClick={() => onCopyToClipBoard(email)}
                    icon={'copy'}
                    type="light"
                  />
                </div>
              ) : null}
              {phone_number ? (
                <div className="d-flex flex-row justify-content-between align-items-center fs--1 text-black">
                  <div className="flex-1 overflow-hidden">
                    {phoneFormatter(phone_number)}
                  </div>
                  <IconFA
                    onClick={() => onCopyToClipBoard(phone_number)}
                    icon={'copy'}
                    type="light"
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div>
              <SelectInput
                // value={tempCus}
                options={customerOptions}
                placeholder={t('orders.select_customer')}
                onChange={onSelectCustomer}
                isClearable
                isDisabled={!isGranted}
              />
            </div>
          )}
        </Card.Body>
      </Card>
      {/* Add/edit modal */}
      <AddEditModal
        onHide
        value={user}
        setValue={setUser}
        show={showModal}
        setShow={setShowModal}
        responseData={(res) => {
          onSave?.(res);
        }}
        size="lg"
        ValueForm={UserFormModal}
      />
    </Form>
  );
};
export default CustomerCard;
