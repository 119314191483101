import { ReactNode } from 'react';
import { UserRole } from './user.type';

export type KeyLabelPair = {
  key: string;
  label: string;
};
export type ValueLabelPair = {
  key: string;
  label: string;
};
export enum StatusEnum {
  ACTIVE = 'active',
  DRAFT = 'draft',
  CLOSED = 'closed',
  PENDING = 'pending',
}

export type AddressInfo = {
  _id?: string;
  zip_code?: string | number;
  country?: string;
  state?: string;
  city?: string;
  lat?: string;
  lon?: string;
  location?: string;
  address_line1?: string;
  address_line2?: string;
};

export const STATUS_INFO = {
  [StatusEnum.ACTIVE]: {
    icon: 'check-circle',
    color: 'green',
    label: 'general.active',
  },
  [StatusEnum.DRAFT]: {
    icon: 'save',
    color: 'blue',
    label: 'general.draft',
  },
  [StatusEnum.CLOSED]: {
    icon: 'archive',
    color: 'secondary',
    label: 'general.closed',
  },
  [StatusEnum.PENDING]: {
    icon: 'clock',
    color: 'yellow',
    label: 'general.pending',
  },
};
