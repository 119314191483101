import { AddressInfo, StatusEnum } from './common.type';
import { WeightUniEnum } from './setting.type';

export type SellerItem = {
  _id?: string;
  first_name?: string;
  last_name?: string;
  store_name?: string;
  fulfill_type?: string;
  status?: StatusEnum;
  address?: AddressInfo;
  phone_number?: string;
  logo?: any;
  logo_url?: string;
  email?: string;
  business_type?: string;
  legal_business_name?: string;
  tax_id?: string;
  files?: any[];
  banners?: any[];
};

export type FlatRateItem = {
  _id?: string;
  name?: string;
  amount?: number;
  estimated_days?: number;
  conditions?: FlatRateCondition;
};
export type FlatRateForm = {
  name: string;
  amount: number;
  estimated_days: number;
  is_turn_on: boolean;
  type: SellerFlatRateConditionType;
  min_price?: number;
  max_price?: number;
  weight_unit?: WeightUniEnum;
  min_weight?: number;
  max_weight?: number;
};

export type FlatRateCondition = {
  is_turn_on: boolean;
  type: SellerFlatRateConditionType;
  min_price?: number;
  max_price?: number;
  weight_unit?: WeightUniEnum;
  min_weight?: number;
  max_weight?: number;
};

export enum SellerFlatRateConditionType {
  ORDER_PRICE = 'order_price',
  ITEM_WEIGHT = 'item_weight',
}
export type ShippingConfig = {
  _id: string;
  is_live_rates: boolean;
  zones: { country_code: string; state_codes: string[] }[];
};
export type StateItem = { code: string; name: string };
export type CountryItem = {
  code: string;
  name: string;
  states: StateItem[];
};
