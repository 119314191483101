import IconFA from 'components/common/IconFA';
import { currencyFormatter } from 'helpers/utils';
import { t } from 'i18next';
import OrderItem from './OrderItem';
import { useEffect, useMemo, useState } from 'react';
import { Card, Col, FormControl, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShippingMethodCard from './ShippingMethodCard';
import dayjs from 'dayjs';
import { getColor } from '../../../helpers/utils';
import OrderStatus from 'pages/orders/components/OrderStatus';

const SellerCard = ({
  seller,
  onChangeQuantity,
  onDeleteVariant,
  cacheQuantity,
  isAdd,
  shipping,
  onChangeShipping,
  onRefetchShipping,
  gettingShipping,
  isCompleted,
}) => {
  const { store_name, _id, products, order } = seller || {};
  const storeLink = `/sellers/${_id}`;
  const [selectedShipping, setSelectedShipping] = useState();
  const items = useMemo(() => {
    let array = [];
    for (let i = 0; i < products.length; i++) {
      const product = products[i];
      for (let y = 0; y < product?.variants.length; y++) {
        const variant = product?.variants[y];
        array.push({ ...variant, product_id: product?._id });
      }
    }
    return array;
  }, [seller]);
  useEffect(() => {
    if (selectedShipping?.id) {
      onChangeShipping?.(selectedShipping);
    }
  }, [selectedShipping]);
  useEffect(() => {
    if (
      !selectedShipping ||
      !shipping?.shipping_items?.find((x) => x?.id === selectedShipping?.id)
    ) {
      setSelectedShipping(shipping?.shipping_items?.[0]);
    }
  }, [shipping]);
  return (
    <Card className="mb-3">
      <Card.Header>
        {order ? (
          <div className="mb-2 d-flex flex-row justify-content-between">
            <div>
              <span>
                <span>{t('orders.order')}</span>{' '}
                <span className="fw-semi-bold text-primary">
                  <Link to={`/orders/${order?._id}`}>#{order?.code}</Link>
                </span>{' '}
                {t('general.created_date').toLowerCase()}{' '}
                {dayjs
                  .unix(order?.created_on)
                  .format('MMMM DD YYYY, [' + t('general.at') + '] HH:mm')}
              </span>
            </div>
            <OrderStatus status={order?.order_status} />
          </div>
        ) : null}
        <div>
          <IconFA icon="store" className={' me-1'} type="light" />
          {t('orders.store')}: <Link to={storeLink}>{store_name}</Link>
        </div>
      </Card.Header>
      <Card.Body className="pt-0">
        {/* HEADER */}
        <Row className="mb-2">
          <Col xs={5} md={5}>
            <div style={{ fontSize: 13, fontWeight: '600' }}>
              {t('orders.products')}
            </div>
          </Col>
          <Col xs={2} md={2}>
            <div
              style={{ fontSize: 13, fontWeight: '600', textAlign: 'right' }}
            >
              {t('orders.quantity')}
            </div>
          </Col>
          <Col xs={2} md={2}>
            <div
              style={{ fontSize: 13, fontWeight: '600', textAlign: 'right' }}
            >
              {t('orders.unit_price')}
            </div>
          </Col>
          <Col xs={2} md={2}>
            <div
              style={{
                fontSize: 13,
                fontWeight: '600',
                textAlign: 'right',
              }}
            >
              {t('orders.amount')}
            </div>
          </Col>
          <Col xs={1} md={1}></Col>
        </Row>
        {/* ITEMS */}
        {items?.map((item, index) => {
          const { price = 0, discount_price = 0 } = item || {};
          const quantity =
            cacheQuantity?.[item?.variant_id] || item?.quantity || 1;
          const isOnSale = discount_price < price;
          const amount = price * quantity || 0;
          const discountAmount = discount_price * quantity || 0;
          return (
            <Row
              key={item?._id}
              className="fs--1 fw-medium border-top border-200 py-2"
            >
              <Col xs={5} md={5}>
                <OrderItem key={index} item={item} index={index} />
              </Col>
              <Col xs={2} md={2} className="d-flex flex-end-center">
                {isCompleted ? (
                  <div className="">{quantity}</div>
                ) : (
                  <FormControl
                    min={1}
                    max={item?.available_stock}
                    value={quantity}
                    defaultValue={item?.quantity || 1}
                    onChange={(value) => {
                      const qty =
                        value?.target?.value > item?.available_stock
                          ? item?.available_stock
                          : value?.target?.value;

                      onChangeQuantity?.(
                        item?.product_id,
                        item?.variant_id,
                        parseInt(qty),
                      );
                    }}
                    size={'sm'}
                    type="number"
                  />
                )}
              </Col>
              <Col
                xs={2}
                md={2}
                className="d-flex flex-column align-items-end justify-content-center"
              >
                <div className={`${isOnSale ? 'text-danger ' : ''}`}>
                  {currencyFormatter.format(discount_price)}
                </div>
                {isOnSale && (
                  <div className="text-500 text-decoration-line-through">
                    {currencyFormatter.format(price)}
                  </div>
                )}
              </Col>
              <Col
                xs={2}
                md={2}
                className="d-flex flex-column align-items-end justify-content-center"
              >
                <div className={`${isOnSale ? 'text-danger ' : ''}`}>
                  {currencyFormatter.format(discountAmount)}
                </div>
                {isOnSale && (
                  <div className="text-500 text-decoration-line-through">
                    {currencyFormatter.format(amount)}
                  </div>
                )}
              </Col>
              <Col
                xs={1}
                md={1}
                className="d-flex flex-column align-items-end justify-content-center"
              >
                {isCompleted ? null : (
                  <IconFA
                    icon={'trash'}
                    type="light"
                    onClick={() => onDeleteVariant?.(item)}
                    color={getColor('gray-500')}
                  />
                )}
              </Col>
            </Row>
          );
        })}
        {isAdd || isCompleted ? null : (
          <ShippingMethodCard
            key={seller?._id}
            shipping={shipping}
            selectedShipping={selectedShipping}
            setSelectedShipping={setSelectedShipping}
            onRefetchShipping={onRefetchShipping}
            gettingShipping={gettingShipping}
          />
        )}
      </Card.Body>
    </Card>
  );
};
export default SellerCard;
