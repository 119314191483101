import {
  Button,
  Flex,
  Group,
  InputError,
  InputLabel,
  Modal,
  Select,
  Switch,
  SwitchGroup,
  TextInput,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { t } from 'i18next';
import React, { ReactNode, useEffect, useState } from 'react';
import {
  PackageItem,
  PackageTypeEnum,
  PackageUnitEnum,
  WeightUniEnum,
} from 'types';
import { getPackageTypeName } from '..';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { useCreatePackage, useUpdatePackage } from 'apis/PackagesApi';
import { toast } from 'react-toastify';
import { InvalidateQueryFilters, useQueryClient } from '@tanstack/react-query';
import { useUserProfile } from 'apis/AuthApis';
type PropType = {
  children?: ReactNode;
  className?: string;
  packageData?: PackageItem;
  onClose: () => void;
};
const PackageDetailModal = (props: PropType) => {
  // ### CONSTANTs
  const { onClose, className = '', packageData } = props;
  const isEdit = packageData?._id;
  const queryClient = useQueryClient();
  const [saving, setSaving] = useState(false);
  const form = useForm({
    initialValues: {
      name: '',
      length: '',
      width: '',
      height: '',
      type: PackageTypeEnum.BOX,
      unit: PackageUnitEnum.FT,
      weight: '',
      weight_unit: WeightUniEnum.LB,
      is_default: false,
    },
    validate: {
      name: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.name'),
        }),
      type: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.type'),
        }),
      unit: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.unit'),
        }),
      weight_unit: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.weight_unit'),
        }),
      width: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.width'),
        }),
      height: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.height'),
        }),
      length: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.length'),
        }),
      weight: (value) =>
        !value &&
        t('form.invalid.required', {
          fieldName: t('packages.weight'),
        }),
    },
  });
  const { mutate: updatePackage, isLoading: updating } =
    useUpdatePackage() as any;
  const { mutate: createPackage, isLoading: creating } =
    useCreatePackage() as any;

  const { isFulfillNailzy: isFBN } = useUserProfile() as any;

  useEffect(() => {
    if (packageData) {
      form.setValues({
        name: packageData?.name || '',
        length: packageData?.length || '',
        width: packageData?.width || '',
        height: packageData?.height || '',
        type: packageData?.type || PackageTypeEnum.BOX,
        unit: packageData?.unit || PackageUnitEnum.FT,
        weight_unit: packageData?.weight_unit || WeightUniEnum.LB,
        weight: packageData?.weight,
        is_default: !packageData?.is_default,
      } as any);
    }
  }, [packageData]);
  const options = [
    {
      value: PackageTypeEnum.BOX,
      label: getPackageTypeName(PackageTypeEnum.BOX),
    },
    {
      value: PackageTypeEnum.ENVELOPE,
      label: getPackageTypeName(PackageTypeEnum.ENVELOPE),
    },
  ];
  // ### FUNCTIONs
  const onSubmit = async () => {
    await form.validate();
    if (form.isValid()) {
      setSaving(true);
      const values = form.getValues();
      let submitData = {
        ...values,
      };
      if (isEdit) {
        updatePackage(
          { ...submitData, _id: packageData?._id },
          {
            onSuccess: () => {
              toast.success(t('general.update_success'));
              onFinish();
            },
          },
        );
      } else {
        createPackage(submitData, {
          onSuccess: () => {
            toast.success(t('general.create_success'));
            onFinish();
          },
        });
      }
    }
  };
  const onFinish = () => {
    form.reset();
    setSaving(false);
    onClose();
    setTimeout(() => {
      queryClient.invalidateQueries([
        'useGetPackages',
      ] as InvalidateQueryFilters);
    }, 300);
  };
  // ### RENDERs
  return (
    <Modal
      centered
      size={'lg'}
      onClose={onClose}
      title={t(isEdit ? 'packages.edit_package' : 'packages.add_package')}
      opened={!!packageData}
    >
      <div className="gap-2 flex flex-col">
        <TextInput
          required
          {...form.getInputProps('name')}
          label={t('packages.package_name')}
          placeholder="Large box"
          error={form.errors?.name}
          disabled={isFBN}
        />

        <Select
          label={t('general.type')}
          data={options}
          {...form.getInputProps('type')}
          required
          placeholder="Box"
          allowDeselect={false}
          disabled={isFBN}
        />
        <div>
          <InputLabel required>{t('products.dimensions_lwh')}</InputLabel>
          <Flex gap={'sm'}>
            <TextInput
              required
              {...form.getInputProps('length')}
              placeholder={t('packages.length')}
              disabled={isFBN}
            />
            <TextInput
              required
              {...form.getInputProps('width')}
              placeholder={t('packages.width')}
              disabled={isFBN}
            />
            <TextInput
              required
              {...form.getInputProps('height')}
              placeholder={t('packages.height')}
              disabled={isFBN}
            />
            <Select
              data={[PackageUnitEnum.FT, PackageUnitEnum.IN]}
              {...form.getInputProps('unit')}
              required
              allowDeselect={false}
              placeholder={t('packages.unit')}
              disabled={isFBN}
            />
          </Flex>
        </div>
        <div>
          <InputLabel required>{t('products.weight')}</InputLabel>
          <Flex gap={'sm'}>
            <TextInput
              flex={1}
              required
              {...form.getInputProps('weight')}
              placeholder={t('packages.weight')}
              disabled={isFBN}
            />
            <Select
              data={[WeightUniEnum.LB, WeightUniEnum.OZ]}
              {...form.getInputProps('weight_unit')}
              required
              placeholder="Weight unit"
              allowDeselect={false}
              disabled={isFBN}
            />
          </Flex>
        </div>
        <Switch
          className="mt-2"
          defaultChecked={packageData?.is_default}
          label={t('general.make_as_default')}
          {...form.getInputProps('is_default')}
          disabled={isFBN}
        />
      </div>
      <SectionFooter>
        <Button
          type="submit"
          onClick={onSubmit}
          disabled={isFBN}
          loading={saving || updating || creating}
        >
          {t(isEdit ? 'general.save' : 'general.create')}
        </Button>
        <Button variant="default" onClick={onClose}>
          {t('general.close')}
        </Button>
      </SectionFooter>
    </Modal>
  );
};
export default PackageDetailModal;
