import { ReactNode } from 'react';
type PropType = {
  children?: ReactNode;
  className?: string;
};
const SectionFooter = (props: PropType) => {
  // ### CONSTANTs
  const { children, className } = props;
  // ### FUNCTIONs
  // ### RENDERs
  return (
    <div
      className={`flex flex-row-reverse w-full gap-2 items-end ${className}`}
    >
      {children}
    </div>
  );
};
export default SectionFooter;
