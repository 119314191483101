import IconFA from 'components/common/IconFA';
import { phoneFormatter } from 'helpers/utils';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { onCopyToClipBoard } from '../../../../helpers/utils';
import { useAuth } from 'contexts/AuthContext';
import { APP_ROLES } from 'constants/constants';
import { Link } from 'react-router-dom';

export const Customer = ({ customer }) => {
  const { t } = useTranslation();
  const { user } = useAuth();
  const isSeller = user?.type === APP_ROLES.seller;
  const formattedPhone = React.useMemo(
    () => phoneFormatter(customer?.phone_number),
    [],
  );
  const email = customer?.email;
  const fullName = `${customer?.first_name} ${customer?.last_name}`;
  const customerLink = `/customers/${customer?._id}`;

  return (
    <Card className="mb-3">
      <Card.Body>
        <h5>{t('orders.customer')}</h5>
        <div className="fs--1 text-black">
          {!isSeller ? (
            <Link to={customerLink}>
              <p>{fullName}</p>
            </Link>
          ) : (
            <p>{fullName}</p>
          )}
          {email && (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <p>{email}</p>
              <IconFA
                onClick={() => onCopyToClipBoard(email)}
                icon={'copy'}
                type="regular"
              />
            </div>
          )}
          {formattedPhone && (
            <div className="d-flex flex-row align-items-center justify-content-between">
              <p>{formattedPhone}</p>
              <IconFA
                onClick={() => onCopyToClipBoard(formattedPhone)}
                icon={'copy'}
                type="regular"
              />
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};
