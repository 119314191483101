import React, { ReactNode } from 'react';
import { Card, Flex, Skeleton } from '@mantine/core';
type PropType = {
  children?: ReactNode;
  className?: string;
};
const ListPageSkeleton = (props: PropType) => {
  // ### CONSTANTs
  const { children, className = '' } = props;
  // ### FUNCTIONs
  // ### RENDERs
  const array = [1, 2, 3, 4, 5, 6, 7];
  const Row = () => (
    <Flex w={'100%'} justify={'space-between'} flex={'row'} gap="md">
      {array.map((i, index) => (
        <Skeleton
          key={index}
          height={15}
          flex={1}
          radius="xs"
          mt="lg"
          mx={'md'}
        />
      ))}
    </Flex>
  );

  return (
    <div className={className}>
      <Skeleton height={20} width={'20%'} radius="xs" mb={'sm'} mt={'md'} />
      <Card className="gap-2">
        <Flex w={'100%'} justify={'space-between'} flex={'row'} mb="md">
          <Skeleton height={30} width={120} radius="xs" />
          <Flex>
            <Skeleton me="sm" height={35} width={110} radius="xs" />
            <Skeleton height={35} width={110} radius="xs" />
          </Flex>
        </Flex>
        <Flex w={'100%'} justify={'space-between'} flex={'row'}>
          <Skeleton height={30} flex={1} me="sm" radius="xs" />
          <Flex>
            <Skeleton me="sm" height={30} width={80} radius="xs" />
            <Skeleton height={30} width={80} radius="xs" />
          </Flex>
        </Flex>
        <Flex
          w={'100%'}
          justify={'space-between'}
          flex={'row'}
          gap="md"
          mt={'md'}
        >
          {array.map((a, i) => (
            <Skeleton key={i} height={25} flex={1} radius="xs" />
          ))}
        </Flex>
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Row />
        <Flex w={'100%'} justify={'space-between'} flex={'row'} mt="md">
          <Skeleton height={25} width={50} radius="xs" />
          <Flex gap={'sm'}>
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
            <Skeleton height={25} width={25} radius="xs" />
          </Flex>
        </Flex>
      </Card>
    </div>
  );
};
export default ListPageSkeleton;
