import { InputError, MultiSelect, Select } from '@mantine/core';
import { useInfiniteQuery } from '@tanstack/react-query';
import { apiCallV2 } from 'apis/ApiCall';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
type PropType = {
  route: string;
  optionTransformer: (data: any) => any[];
  className?: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  errorMessage?: string;
  renderOption?: any;
} & (
  | {
      multiple?: false;
      value: string | null;
      onChange: (id: string) => void;
    }
  | {
      multiple: true;
      value: string[] | null;
      onChange: (ids: string[]) => void;
    }
);
const CommonSelect = (props: PropType) => {
  // ### CONSTANTs
  const controller = new AbortController();
  const LIMIT = 1000;
  const {
    route = '',
    className = '',
    value,
    label,
    placeholder,
    onChange,
    multiple,
    required,
    errorMessage,
    renderOption,
    optionTransformer = (data) =>
      data?.results?.map((x: any) => ({
        label: x?.name,
        value: x?._id,
      })),
  } = props;
  // const [page, setPage] = useState(1);
  const [data, setData] = useState<any[]>([]);

  const {
    data: res,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: [route, 'infinity'],
    initialPageParam: 1,
    queryFn: ({ pageParam = 1 }) =>
      apiCallV2({
        url: route,
        params: { limit: LIMIT, page: pageParam },
        signal: controller.signal,
      } as any),
    getNextPageParam: (lastPage: any, pages) => {
      const hasMore = lastPage?.data?.total > pages.length * LIMIT;
      const nextPage = pages.length + 1;
      // console.log('________newData:lastPage', lastPage, nextPage, hasMore);
      return hasMore ? nextPage : undefined;
    },
    enabled: !!route,
  });

  useEffect(() => {
    let newData: any[] = [];
    res?.pages?.map((page: any) =>
      optionTransformer(page?.data)?.map((item: any) => newData.push(item)),
    ) || [];
    console.log('________newData:lastPage', newData);
    setData(newData);
  }, [res]);
  // ### FUNCTIONs
  // ### RENDERs
  const generalProps = {
    error: !!errorMessage,
    required: required,
    label: label,
    searchable: true,
    renderOption: renderOption,
    placeholder: placeholder,
    data: data,
    rightSection:
      isLoading || isFetchingNextPage ? (
        <FontAwesomeIcon spin icon="spinner" size="1x" />
      ) : undefined,
    // onTransitionEnd: () => {
    //   console.log('________newData:onTransitionEnd', hasNextPage);
    //   if (hasNextPage) {
    //     fetchNextPage();
    //   }
    // },
  };
  return (
    <div className={className}>
      {multiple ? (
        <MultiSelect
          {...generalProps}
          hidePickedOptions
          value={value as string[]}
          onChange={(value) => {
            onChange?.(value as string[]);
          }}
        />
      ) : (
        <Select
          {...generalProps}
          value={value}
          onChange={(value) => {
            onChange?.(value as string);
          }}
          // onEnded={() => console.log('________:onEnded')}
          // onEndedCapture={() => console.log('________:onEndedCapture')}
          // onDragEnd={() => console.log('________:onDragEnd')}
          // onDragEndCapture={() => console.log('________:onDragEndCapture')}
          // onTouchEnd={() => console.log('________:onTouchEnd')}
          // onTouchEndCapture={() => console.log('________:onTouchEndCapture')}
          // onAnimationEnd={() => console.log('________:onAnimationEnd')}
          // onAnimationEndCapture={() =>
          //   console.log('________:onAnimationEndCapture')
          // }
          // onTransitionEnd={() => console.log('________:onTransitionEnd')}
          // onTransitionEndCapture={() =>
          //   console.log('________:onTransitionEndCapture')
          // }
          // onCompositionEnd={() => console.log('________:onCompositionEnd')}
          // onCompositionEndCapture={() =>
          //   console.log('________:onCompositionEndCapture')
          // }
          // onEncrypted={() => console.log('________:onEncrypted')}
          // onEncryptedCapture={() => console.log('________:onEncryptedCapture')}
          // onSuspend={() => console.log('________:onSuspend')}
          // onSuspendCapture={() => console.log('________:onSuspendCapture')}
          // onScroll={() => console.log('________:onScroll')}
          // onScrollCapture={() => console.log('________:onScrollCapture')}
        />
      )}
      {errorMessage ? (
        <InputError className="mt-1">{errorMessage}</InputError>
      ) : null}
    </div>
  );
};
export default CommonSelect;
