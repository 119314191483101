import { lightIcons } from 'configs/fortawesome/light';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { brandsIcons } from 'configs/fortawesome/brands';
import { solidIcons } from 'configs/fortawesome/solid';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
type Props = {
  className?: string;
  icon: any;
  color?: string;
  type?: 'solid' | 'light' | 'brands';
  onClick?: () => void;
  bgColor?: string;
  size?: SizeProp;
};
const IconFA = ({
  icon = '', // fa-solid, fa-light, fa-brands
  color = '',
  type = 'solid',
  onClick,
  bgColor = 'transparent',
  className = '',
  size = '1x',
}: Props) => {
  return (
    <span
      onClick={onClick}
      className={`${onClick ? 'icon-fa-button' : ''}`}
      style={{ backgroundColor: bgColor }}
    >
      <FontAwesomeIcon
        size={size}
        className={className}
        icon={
          typeof icon === 'string'
            ? type === 'light'
              ? (lightIcons as any)[icon.replace('fa-', '')]
              : type === 'brands'
              ? (brandsIcons as any)[icon.replace('fa-', '')]
              : (solidIcons as any)[icon?.replace('fa-', '')]
            : icon
        }
        color={color}
      />
    </span>
  );
};
export default IconFA;
