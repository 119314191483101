import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FalconCloseButton from 'components/common/FalconCloseButton';
import { Col, Row, Form, Button, Modal, Image } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useCreateUserByAdmin, useUpdateUserByAdmin } from 'apis/UsersApi';
import {
  handleMutation,
  useCreateCustomer,
  useUpdateCustomer,
} from 'helpers/mutation-utils';
import LoadingButton from 'components/common/buttons/LoadingButton';
import { useQueryClient } from '@tanstack/react-query';
import { userKeys } from '../queryKeys';
import { phoneUnFormatter, resizeImage } from 'helpers/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { getUserValidation } from '../yup-validataion-schemas';
import FlagPhoneInput from 'components/common/phone-input/FlagPhoneInput';
import { useDropzone } from 'react-dropzone';
import NailzyImage from 'components/common/NailzyImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRequestImage from 'hooks/file/useGenerateImageId';
import { t } from 'i18next';
import { toast } from 'react-toastify';
import { syncImagePaths } from 'apis/ImagesApis';
import { getCustomerTitle } from '.';
import Avatar from 'components/common/Avatar';
import IconFA from 'components/common/IconFA';
import CropImageModal from 'components/common/CropImageModal';
import { imageUrlToId } from 'pages/dashboard/components/helpers';
const IMG_URL = process.env.REACT_APP_PRODUCT_URL;

export const UserFormModal = ({
  value,
  setIsChanged,
  handleClose,
  handleResetState,
  responseData,
}) => {
  const queryClient = useQueryClient();

  const isEdit = !!value?._id;

  const {
    control,
    reset,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      status: value?.status || 'active',
      title_type: value?.title_type || 'nail_tech',
      profile_image_url: value?.profile_image_url,
    },
    resolver: yupResolver(getUserValidation()),
  });
  const status = watch('status');
  const profile_image_url = watch('profile_image_url');
  const title_type = watch('title_type');
  const [localImg, setLocalImg] = useState();
  const [saving, setSaving] = useState(false);
  const [business_phone, setPhoneNumber] = useState(value?.business_phone);
  const [phone_number, setBusinessPhone] = useState(value?.phone_number);
  const [isLogoHovered, setIsLogoHovered] = useState(false);
  const { mutate: createCustomer, isLoading: isCreating } = useCreateCustomer();
  const { createImgID } = useRequestImage();
  const { mutate: updateCustomer, isLoading: isUpdating } = useUpdateCustomer();

  const onSave = async (inputData) => {
    setSaving(true);
    const {
      _id,
      first_name,
      last_name,
      phone_number,
      business_name,
      title_type,
      profile_image_url,
      email,
      status,
    } = inputData;
    let submitData = {
      id: value?._id,
      first_name: first_name?.trim(),
      last_name: last_name?.trim(),
      phone_number: phone_number?.trim() || '',
      business_name: business_name?.trim() || '',
      email: email?.trim() || '',
      business_phone: business_phone?.trim() || '',
      title_type,
      profile_image_id: imageUrlToId(profile_image_url) || null,
      status,
    };
    if (localImg) {
      const image_url = await createImgID(localImg, 'customers', false, {
        maxWidth: 500,
        maxHeight: 500,
      });
      submitData = {
        ...submitData,
        profile_image_id: imageUrlToId(image_url),
      };
      syncImagePaths([image_url]);
    }
    if (isEdit) {
      updateCustomer(submitData, {
        onSuccess: () => {
          queryClient.invalidateQueries(userKeys.lists());
          reset();
          handleResetState();
          setSaving(false);
          toast.success(t('general.update_success'));
        },
        onError: (err) => {
          toast.error(err?.description?.message || err?.message);
        },
      });
    } else {
      createCustomer(submitData, {
        onSuccess: (res) => {
          queryClient.invalidateQueries(userKeys.lists());
          reset();
          handleResetState();
          setSaving(false);
          // responseData(res?.data);
          toast.success(t('general.create_success'));
        },
        onError: (err) => {
          toast.error(err?.description?.message || err?.message);
        },
      });
    }
  };

  const [imgToCrop, setImageToCrop] = useState(null);
  const onSaveImg = async (croppedImage) => {
    setLocalImg(
      Object.assign(croppedImage, {
        preview: URL.createObjectURL(croppedImage),
      }),
    );
    setImageToCrop(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,

    onDrop: async (acceptedFiles) => {
      const file = await URL.createObjectURL(acceptedFiles[0]);
      setImageToCrop(file);
    },
  });

  useEffect(() => {
    if (isDirty) setIsChanged(true);
  }, [isDirty]);

  const isLoading = isCreating || isUpdating;

  return (
    <>
      <Form onSubmit={handleSubmit(onSave, (e) => {})}>
        <Modal.Header>
          <Modal.Title>
            {isEdit
              ? t('customer.edit_customer')
              : t('customer.create_customer')}
          </Modal.Title>
          <FalconCloseButton onClick={handleClose} />
        </Modal.Header>

        <Modal.Body>
          <Row className="justify-content-center">
            <Col md={12}>
              <Form.Label>{t('general.profile_picture')}</Form.Label>
              <div
                style={{
                  width: 100,
                }}
                onMouseLeave={() => setIsLogoHovered(false)}
                onMouseOver={() => setIsLogoHovered(true)}
              >
                <div
                  {...getRootProps({
                    style: {
                      width: 100,
                      height: 100,
                      borderRadius: 100,
                    },
                  })}
                >
                  <input {...getInputProps()} />
                  {localImg ? (
                    <Image
                      roundedCircle
                      width={'100%'}
                      src={
                        localImg?.media_url
                          ? `${IMG_URL}/${localImg?.media_url}`
                          : URL.createObjectURL(localImg)
                      }
                      alt={localImg?.path}
                      style={{
                        width: 100,
                        borderRadius: 100,
                        height: 100,
                      }}
                    />
                  ) : profile_image_url ? (
                    <NailzyImage
                      roundedCircle
                      style={{
                        width: 100,
                        borderRadius: 100,
                        height: 100,
                      }}
                      image_url={profile_image_url}
                    />
                  ) : (
                    <Avatar
                      className="w-100 h-100"
                      name={`${value?.first_name} ${value?.last_name}`}
                      size="4xl"
                    />
                  )}
                </div>
                <div
                  style={{
                    cursor: 'pointer',
                    width: 100,
                    display:
                      (localImg || profile_image_url) && isLogoHovered
                        ? 'flex'
                        : 'none',
                  }}
                  className="mt-2 justify-content-center"
                >
                  <IconFA
                    onClick={() => {
                      setLocalImg(null);
                      setValue('profile_image_url', undefined);
                    }}
                    icon="trash-can"
                    type="light"
                    color="red"
                  />
                </div>
              </div>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="fisrt_name">
                <Form.Label>
                  {t('register.form.first_name')}{' '}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="John"
                  defaultValue={value?.first_name}
                  isInvalid={!!errors.first_name}
                  {...register('first_name')}
                />
                <Form.Text className="text-danger">
                  {errors.first_name && errors.first_name.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="last-name">
                <Form.Label>
                  {t('register.form.last_name')}{' '}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Doe"
                  defaultValue={value?.last_name}
                  isInvalid={!!errors.last_name}
                  {...register('last_name')}
                />
                <Form.Text className="text-danger">
                  {errors.last_name && errors.last_name.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="email">
                <Form.Label>
                  {t('register.form.email')}{' '}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="abc123@gmail.com"
                  defaultValue={value?.email}
                  isInvalid={!!errors.email}
                  {...register('email')}
                />
                <Form.Text className="text-danger">
                  {errors.email && errors.email.message}
                </Form.Text>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3" controlId="phone_number">
                <Form.Label>
                  {t('general.phone')} <span className="text-danger">*</span>
                </Form.Label>
                <FlagPhoneInput
                  name="phone_number"
                  control={control}
                  errors={errors}
                  value={phone_number}
                  placeholder="214 123 4567"
                  type="text"
                  defaultValue={value?.phone_number}
                  onChange={setPhoneNumber}
                  isInvalid={!!errors.phone_number}
                />
                <Form.Text className="text-danger">
                  {errors.phone_number && errors.phone_number.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="business_name">
                <Form.Label>{t('customer.business_name')} </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ABC company"
                  defaultValue={value?.business_name}
                  isInvalid={!!errors.business_name}
                  {...register('business_name')}
                />
                <Form.Text className="text-danger">
                  {errors.business_name && errors.business_name.message}
                </Form.Text>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3" controlId="business_phone">
                <Form.Label>{t('customer.business_phone')} </Form.Label>
                <FlagPhoneInput
                  name="business_phone"
                  control={control}
                  errors={errors}
                  value={business_phone}
                  placeholder="214 123 4567"
                  type="text"
                  defaultValue={value?.business_phone}
                  onChange={setBusinessPhone}
                  isInvalid={!!errors.business_phone}
                />
                {/* <Form.Text className="text-danger">
                  {errors.business_phone && errors.business_phone.message}
                </Form.Text> */}
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Label>
                {t('customer.role_type')} <span className="text-danger">*</span>
              </Form.Label>
              <div key={`inline-${'radio'}`} className="mb-3">
                <Form.Check
                  inline
                  onChange={(e) => setValue('title_type', 'nail_tech')}
                  checked={title_type === 'nail_tech'}
                  label={getCustomerTitle('nail_tech')}
                  name="title_type"
                  type={'radio'}
                  id={`nail_tech`}
                />
                <Form.Check
                  inline
                  onChange={(e) => setValue('title_type', 'salon_owner')}
                  checked={title_type === 'salon_owner'}
                  label={getCustomerTitle('salon_owner')}
                  name="title_type"
                  type={'radio'}
                  id={`salon_owner`}
                />
                <Form.Check
                  inline
                  name="title_type"
                  label={getCustomerTitle('nail_supply')}
                  type={'radio'}
                  id={`nail_supply`}
                  onChange={(e) => setValue('title_type', 'nail_supply')}
                  checked={title_type === 'nail_supply'}
                />

                <Form.Check
                  inline
                  name="title_type"
                  label={getCustomerTitle('other')}
                  value={'other'}
                  type={'radio'}
                  onChange={(e) => setValue('title_type', 'other')}
                  id={`other`}
                  checked={title_type === 'other'}
                />
              </div>
            </Col>
            <Col md={12}>
              <Form.Label>
                {t('general.status')} <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                value={status}
                size="md"
                className="me-2"
                onChange={(e) => {
                  setValue('status', e?.target?.value);
                }}
              >
                <option value={'active'}>{t('general.active')}</option>
                <option value={'closed'}>{t('general.closed')}</option>
                <option value={'draft'}>{t('general.draft')}</option>
              </Form.Select>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="falcon-default" onClick={handleClose}>
            {t('general.close')}
          </Button>
          <LoadingButton
            size="md"
            type="submit"
            isLoading={saving || isLoading}
          >
            {t('general.save')}
          </LoadingButton>
        </Modal.Footer>

        <CropImageModal
          imgToCrop={imgToCrop}
          onHide={() => setImageToCrop(null)}
          onSave={(file) => onSaveImg(file)}
        />
      </Form>
    </>
  );
};

UserFormModal.propTypes = {
  value: PropTypes.object,
  setIsChanged: PropTypes.func,
  handleClose: PropTypes.func,
  handleResetState: PropTypes.func,
};
