import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faApple,
  faCcAmex,
  faCcDinersClub,
  faCcDiscover,
  faCcJcb,
  faCcMastercard,
  faCcStripe,
  faCcVisa,
  faFacebook,
  faFacebookSquare,
  faGoogle,
  faInstagram,
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faFacebookSquare,
  faInstagram,
  faCcVisa,
  faCcMastercard,
  faCcJcb,
  faCcDiscover,
  faCcAmex,
  faCcDinersClub,
  faCcStripe,
  faFacebook,
  faApple,
  faGoogle,
);

export const brandsIcons = {
  'facebook-square': faFacebookSquare,
  instagram: faInstagram,
  'cc-visa': faCcVisa,
  'cc-mastercard': faCcMastercard,
  'cc-jcb': faCcJcb,
  'cc-discover': faCcDiscover,
  'cc-amex': faCcAmex,
  'cc-diners-club': faCcDinersClub,
  'cc-stripe': faCcStripe,
  facebook: faFacebook,
  apple: faApple,
  google: faGoogle,
};
