import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { useCallback, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import AsyncSelectInput from './AsyncSelectInput';

const libraries = ['places'];

const getAddressDetail = (addressComponents = []) => {
  const address = {};
  for (let i = 0; i < addressComponents.length; i++) {
    const types = addressComponents[i].types;
    const long_name = addressComponents[i].long_name;
    const short_name = addressComponents[i].short_name;
    for (let j = 0; j <= types.length; j++) {
      switch (types[j]) {
        case 'postal_code':
          address.postal_code = { short_name, long_name };
          break;
        case 'country':
          address.country = { short_name, long_name };
          break;
        case 'administrative_area_level_1':
          address.administrative_area_level_1 = { short_name, long_name };
          break;
        case 'administrative_area_level_2':
          address.administrative_area_level_2 = { short_name, long_name };
          break;
        case 'locality':
          address.locality = { short_name, long_name };
          break;
        case 'neighborhood':
          address.neighborhood = { short_name, long_name };
          break;
        case 'route':
          address.route = { short_name, long_name };
          break;
        case 'sublocality_level_1':
          address.sublocality_level_1 = { short_name, long_name };
          break;
        case 'sublocality_level_2':
          address.sublocality_level_2 = { short_name, long_name };
          break;
        case 'premise':
          address.premise = { short_name, long_name };
          break;
        case 'subpremise':
          address.subpremise = { short_name, long_name };
          break;
        case 'street_number':
          address.street_number = { short_name, long_name };
          break;
        default:
          break;
      }
    }
  }

  return address;
};

const AddressInput = ({
  label,
  value,
  onChange,
  isInvalid = false,
  message,
  defaultValue,
  required,
  disabled,
}) => {
  const [placeService, setPlaceService] = useState(undefined);
  const [findPlaceFromQuery, setFindPlaceFromQuery] = useState(undefined);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const onMapLoad = (map) => {
    const service = new window.google.maps.places.AutocompleteService();
    const findPlaceFromQuery = new window.google.maps.places.PlacesService(map);

    setPlaceService(service);
    setFindPlaceFromQuery(findPlaceFromQuery);
  };

  const searchPlaces = useCallback(
    async (query) => {
      try {
        const results = await placeService?.getPlacePredictions({
          input: query,
        });

        return results.predictions;
      } catch (error) {
        console.error(error);
      }
    },
    [placeService],
  );

  const promiseOptions = async (inputValue) => {
    const places = (await searchPlaces(inputValue)) ?? [];

    return places.map((item) => ({
      value: item.place_id,
      label: item.description,
    }));
  };

  const handleLocationSelected = (value) => {
    if (!value) {
      return;
    }
    findPlaceFromQuery.getDetails(
      {
        placeId: value,
        fields: ['formatted_address', 'address_components', 'geometry'],
      },
      (result) => {
        const addressDetail = getAddressDetail(result.address_components);

        onChange?.({
          ...addressDetail,
          location: result.formatted_address,
          lat: result.geometry.location.lat().toString(),
          lon: result.geometry.location.lng().toString(),
        });
      },
    );
  };

  return (
    <Form.Group as={Col} xs={12} controlId={'address'}>
      {isLoaded && <GoogleMap onLoad={onMapLoad} />}

      {label && (
        <Form.Label>
          {label} {required && <span className="text-danger">*</span>}
        </Form.Label>
      )}

      <AsyncSelectInput
        closeMenuOnSelect={false}
        placeholder={'123 Main St, Dallas, TX 75123'}
        classNamePrefix="react-select"
        loadOptions={promiseOptions}
        isClearable={false}
        onChange={(e) => handleLocationSelected(e?.value)}
        isInvalid={isInvalid}
        defaultValue={{ label: defaultValue ?? 'Select' }}
        value={value}
        disabled={disabled}
      />

      <Form.Control.Feedback
        style={{ display: isInvalid ? 'block' : 'none' }}
        type="invalid"
      >
        {message}
      </Form.Control.Feedback>
    </Form.Group>
  );
};

export default AddressInput;
